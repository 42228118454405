import React, { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { namesCache, namesScreens, statusCode } from '../../constants'
import { storageCache, recoverCache } from '../../services/cacheManager'
import {
	filter,
	filterAndRemoveRepeated,
	filterAndRemoveRepeatedByProps,
	paginateToPostApi,
} from '../../services/arrayHandless'
import groupBy from '../../services/groupBy'
import { execptionWithIcons, headerOptions } from '../../api'
import { base64toFile, downLoadFiles } from '../../services/files'
import { formatDate } from '../../services/dateHandless'
import { handleWaring } from '../../services/isValid'
export const requestContext = createContext()
const RequestContext = ({ children }) => {
	const [data, setData] = useState(null)
	const [highway, setHighway] = useState(null)
	const [dealership, setDealership] = useState(null)
	const [contract, setContract] = useState(null)
	const [infoaddress, setInfoAddress] = useState(null)
	const [lote, setLote] = useState(null)
	const [dealershipLote, setDealershipLote] = useState(null)
	const [service, setService] = useState(null)
	const [monitoring_progressservice_work, setMonitoringProgressServiceWork] = useState(null)
	const [monitoring_progress_work, setMonitoringProgressWork] = useState(null)
	const [monitoring_progress, setMonitoringProgress] = useState(null)
	const [servicecat, setServicecat] = useState(null)
	const [serviceSubcat, setServiceSubcat] = useState(null)
	const [contract_Attachment, setContractAttachment] = useState(null)
	const [cardinal_points, setCardinalPoints] = useState(null)
	const [construction_plan, setConstructionPlan] = useState([])
	const [Oversightcount, setOversightCount] = useState(null)
	const [oversight, setOversight] = useState(null)
	const [check_inspection, setCheckInspection] = useState(null)
	const [oversight_location, setOversightLocation] = useState(null)
	const [status, setStatus] = useState(null)
	const [type_highway, setTypeHighway] = useState(null)
	const [deadline, setDeadline] = useState(null)
	const [loading, setLoading] = useState(false)
	const [notification, setNotification] = useState(null)
	const [service_late, setServiceLate] = useState(null)
	const [report, setReport] = useState(null)
	const [complements, setComplements] = useState(null)
	const [groups, setGroups] = useState(null)
	const [obArtType, setObArtType] = useState(null)
	const [obArt, setObArt] = useState(null)
	const [obArtElem, setObArtElem] = useState(null)
	const [obArtClass, setObArtClass] = useState(null)
	const [gerEm, setGerEm] = useState(null)
	const [type_constructions, setTypeConstruction] = useState(null)
	const [syncStatus, setSyncStatus] = useState(null)
	const navigate = useNavigate()
	const [limithigway, setLimitHighway] = useState(null)
	const [userLimithigway, setUserLimitHighway] = useState(null)
	const [infoRequest, setInfoRequest] = useState({
		status: statusCode.successfull,
		message: '',
		color: '',
	})
	const [googleMapsApiKey, setGoogleMapsApiKey] = useState(null)
	const [menus, setMenus] = useState(null)
	const [userMenus, setUserMenus] = useState(null)
	const [editingMenus, setEditingMenus] = useState(null)
	const [company, setCompany] = useState(null)
	const [sac, setSac] = useState(null)
	const [reportData, setReportData] = useState(null)
	const [checklists, setChecklists] = useState(null)
	const [sector, setSector] = useState(null)
	useEffect(() => {
		const controllClearToast = () => {
			if (!loading) {
				storageCache(namesCache.LASS_MESSAGE)
			}
		}
		controllClearToast()
	}, [loading])
	const getItemDealership = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/dealership/${id || '0'}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setDealership(json)
	}
	const getAllItemDealership = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/dealership/all/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getWhereItemDealership = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/dealership/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setDealership(json)
	}
	const postItemDealership = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/dealership/${item.id ? `update/${item.id}` : 'new'}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigate('/dealership')
		}
	}
	const getItemDealershipEmail = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/email/${id || '0'}`, 'get')
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getItemDealershipContract = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/dealership_contract/${id || '0'}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setContract(json)
	}
	const getWhereItemDealershipContract = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/dealership_contract/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setContract(json)
	}
	const postItemDealershipContract = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/dealership_contract/${item.id ? `update/${item.id}` : 'new'}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()
		postItemCardinalPoints({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		navigate('/dealership_contract')
	}
	const getItemDealershipContractAttachment = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/contract_attachment/${id || '0'}`,
			'get'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setContractAttachment(json)
	}
	const postItemDealershipContractAttachment = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/contract_Attachment/${item.id ? `update/${item.id}` : 'new'}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemDealershipContractAttachment(item.id)
		}
	}
	const deleteItemDealershipContractAttachment = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/contract_Attachment/delete/${item.id}`,
			'delete',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
	}
	const getAllItemDealershipLot = async (id) => {
		const { url, options } = headerOptions(
			`/dealership_lot/all/${id || 0}`,
			'get'
		)
		setLoading(true)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			setLoading(false)
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getInfoAdress = async (cep) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/cep/${cep && cep.replace('-', '')}`,
			'get'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setInfoAddress(json)
	}
	const getItemHighway = async (id, rod_concessionaria) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/highway/${id || '0'}/${rod_concessionaria || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setHighway(json)
	}
	const getAllItemHighway = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/highway/all/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setHighway(json)
	}
	const getWhereItemHighway = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/highway/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setHighway(json)
	}
	const postItemHighway = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/highway/${item.id ? `update/${item.id}` : 'new'}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigate('/highway')
		}
	}
	const deleteItemHighway = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/highway/delete/${id}`, 'delete')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		return getItemHighway()
	}
	const deleteItemDealership = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/dealership/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		return getItemDealership()
	}
	const getItemService = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/service/${id || '0'}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setService(json)
	}

	const getItemDERService = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/service/der', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setService(json)
	}
	const getWhereItemService = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/service/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setService(json)
	}
	const getAllItemService = async (id, serv_disp = 0) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/service/all/${id || '0/0'}/${serv_disp}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setService(json)
	}
	const getAllItemServiceTimeLine = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/service/timeline/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setService(json)
	}
	const getItemCategoryService = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/service_category/${id || '0'}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		return setServicecat(json)
	}
	const getAllItemCategoryService = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/service_category/all/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setServicecat(json)
	}
	const getItemServiceSubCat = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/service_sub_category/${id || '0'}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		return setServiceSubcat(json)
	}
	const getAllItemServiceSubCat = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/service_sub_category/all/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		const { data: items } = json
		const remove_repeated_items = filterAndRemoveRepeatedByProps({
			array: items,
			props: 'serv_sub_cat_desc',
		})
		setServiceSubcat({
			data: remove_repeated_items,
			total: remove_repeated_items.length,
		})
	}
	const getAllItemCardinalPoints = async (id, rod_cod) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/cardinal_points/all/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		if (!rod_cod) {
			return setCardinalPoints(json)
		}
		const { data: json_data } = json
		const filteredData = filter(json_data, 'rod_sent_rodovia', rod_cod)
		if (filteredData?.total === 0) {
			return setCardinalPoints(json)
		}
		setCardinalPoints(filteredData)
	}
	const getItemCardinalPoints = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/cardinal_points/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setCardinalPoints(json)
	}
	const postItemCardinalPoints = async (item) => {
		setLoading(true)
		const { id, rod_sent_rodovia } = item
		const { url, options } = headerOptions(
			`/cardinal_points/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			const noCache = true

			return getItemCardinalPoints(rod_sent_rodovia, noCache)
		}
	}
	const deleteItemCardinalPoints = async (item) => {
		setLoading(true)
		const { id, rod_sent_rodovia } = item
		const { url, options } = headerOptions(
			`/cardinal_points/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			const noCache = true
			return getItemCardinalPoints(rod_sent_rodovia, noCache)
		}
	}
	const postItemOversight = async (item) => {
		const { id } = item
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_services/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		setLoading(false)
		return navigate(namesScreens.OVERSIGHT)
	}
	const postItemOversightService = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_services/services/update_status/${item.id ? item.id : 'new'}`,
			item.id ? 'PUT' : 'POST',
			{ ...item }
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		setLoading(false)
		return response.ok
	}
	const getItemOversighttService = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_services/services/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setService(json)
	}
	const getItemServicesConstructionPlan = async ({
		id,
		apos_data_from,
		apos_data_to
	}) => {
		setLoading(true)
		setService(null)
		const { url, options } = headerOptions(
			`/progress_construction_plan/${id || 0}/${apos_data_from ? apos_data_from : null}/${apos_data_to ? apos_data_to : null}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setService(json)
	}
	const getItemMonitoringProgressServiceWork = async ({
		id,
		apos_data_from,
		apos_data_to
	}) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/monitoring_progress/service_work/${id || 0}/${apos_data_from ? apos_data_from : null}/${apos_data_to ? apos_data_to : null}`,
			'get'
		)
		setMonitoringProgressServiceWork(null)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setMonitoringProgressServiceWork(json)
	}
	const getItemMonitoringProgressWork = async ({
		id,
		apos_data_from,
		apos_data_to
	}) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/monitoring_progress/work/${id || 0}/${apos_data_from ? apos_data_from : null}/${apos_data_to ? apos_data_to : null}`,
			'get'
		)
		setMonitoringProgressWork(null)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setMonitoringProgressWork(json)
	}
	const getItemMonitoringProgress = async ({
		id,
	}) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/monitoring_progress/${id || 0}`,
			'get'
		)
		setMonitoringProgress(null)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setMonitoringProgress(json)
	}
	const postItemMonitoringProgress = async ({
		id,
		acob_pl_ob_cod,
		acob_perc_prev,
		acob_date_prev,
		acob_date_prev_fim,
	}) => {
		setLoading(true)
		const state = {
			id,
			acob_pl_ob_cod,
			acob_perc_prev,
			acob_date_prev,
			acob_date_prev_fim,
		}
		const { url, options } = headerOptions(
			`/monitoring_progress/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			state
		)
		setMonitoringProgress(null)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemMonitoringProgress({
				id: acob_pl_ob_cod
			})
		}
		return response?.ok
	}
	const deleteItemOversightService = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_services/services/delete/${item.id}`,
			'delete',
			item
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemOversighttService(item.id)
		}
	}
	const getItemOversighttPhotos = async ({
		id,
		ped_venda_date_from,
		ped_venda_date_to,
	}) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_services/photo/${id || 0}/${ped_venda_date_from || null}/${ped_venda_date_to || null}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		return json
	}
	const postItemOversightPhotos = async (state) => {
		const { id, pvs_loc_foto_pedidovenda, reload } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_services/photo/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok && reload) {
			return getItemOversighttPhotos({
				id: pvs_loc_foto_pedidovenda,
				ped_venda_date_from: null,
				ped_venda_date_to: null
			})
		}
	}
	const deleteItemOversightPhotos = async ({ id }) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_services/photo/delete/${id || 0}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		return response.ok
	}

	const getItemOversight = async (state) => {
		setLoading(true)
		const {
			id,
			conc_cod,
			rod_cod,
			pv_loc_sentido,
			ped_venda_date_from,
			ped_venda_date_to,
			serv_cod,
			serv_cat_cod,
			serv_sub_cat_cod,
			type_date,
			pv_loc_km_ini_from,
			pv_loc_km_ini_to,
			desc,
			gp_cod,
			is_report = false,
		} = state
		const { url, options } = headerOptions(
			`/oversight_services/status/${id || '0'}/${conc_cod || 0}/${rod_cod || 0}/${pv_loc_sentido || 0}/${ped_venda_date_from || null}/${ped_venda_date_to || null}/${serv_cod || 0}/${serv_cat_cod || 0}/${serv_sub_cat_cod || 0}/${type_date || 0
			}/${pv_loc_km_ini_to || null}/${pv_loc_km_ini_from || null}/${desc || null}/${gp_cod || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return null
		}
		if (is_report) {
			return json
		}
		if (!json?.data?.length) {
			storageCache(namesCache.ACTUAL_PAGE)
		}
		setOversight(json)
	}

	const getCountOversight = async (state) => {
		setLoading(true)
		if (state) {
			const { url, options } = headerOptions(`/oversight_services/count/${state}`, 'get')
			const response = await fetch(url, options)
			setLoading(false)
			const json = await response.json()
			if (!response.ok) {
				return setInfoRequest({
					status: response.status,
					message: json.message,
					...execptionWithIcons(response.status),
				})
			}
			setOversightCount(json)
		} else {
			const { url, options } = headerOptions('/oversight_services/count/0/0/0/0/0/0', 'get')
			const response = await fetch(url, options)
			setLoading(false)
			const json = await response.json()
			if (!response.ok) {
				return setInfoRequest({
					status: response.status,
					message: json.message,
					...execptionWithIcons(response.status),
				})
			}
			setOversightCount(json)
		}
	}
	const deleteItemOversight = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_services/delete/${item.id}`,
			'delete',
			item
		)
		const { id, ...rest } = item
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemOversight({
				...rest,
				id,
			})
		}
	}
	const getItemAllStatusOversight = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/status_oversight/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setStatus(json)
	}
	const getItemStatusOversight = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/status_oversight/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}

		setStatus(json)
	}
	const getItemWhereStatusOversight = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/status_oversight/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}

		setStatus(json)
	}
	const postIemStatusOversight = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/status_oversight/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/status')
			return getItemStatusOversight()
		}
	}
	const deleteItemStatusOversight = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/status_oversight/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemStatusOversight()
		}
	}
	const getItemCheckInspection = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/check_inspection/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		return setCheckInspection(json)
	}
	const getAllItemCheckInspection = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/check_inspection/all/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		return setCheckInspection(json)
	}
	const postItemCheckInspection = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/check_inspection/${item.id ? `update/${item.id}` : 'new'}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		return getItemCheckInspection(item.ped_venda_cod)
	}
	const getItemReportOversight = async ({
		id
	}) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/report/oversight/all/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			setData(null)
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getItemReportOversightMap = async (state) => {
		setLoading(true)
		if (!state) {
			return
		}
		const { url, options } = headerOptions(
			`/report/oversight/map/${state}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setReport(json)
	}
	const getItemApiKey = async () => {
		const cache = recoverCache(namesCache.API_KEY_MAP)
		if (cache) {
			return setGoogleMapsApiKey(cache)
		}
		setLoading(true)
		const { url, options } = headerOptions('/apimap', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		storageCache(namesCache.API_KEY_MAP, json.data)
		setGoogleMapsApiKey(json.data)
	}

	const getItemTypeHigway = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/type_highway/all', 'get')
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setTypeHighway(json)
	}
	const postItemService = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/service/${item.id ? `update/${item.id}` : 'new'}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemService()
			navigate('/service')
		}
	}
	const deleteItemService = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/service/delete/${id}`, 'delete')
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemService()
			navigate('/service')
		}
	}
	const deleteItemCategoryService = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/service_category/delete/${item.id}`,
			'delete'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemCategoryService(item.serv_cod)
		}
	}
	const deleteItemCategorySubService = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/service_sub_category/delete/${item.id}`,
			'delete'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemServiceSubCat(item.serv_cat_cod)
		}
	}
	const postItemCategorySubService = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/service_sub_category/${item.id ? `update/${item.id}` : 'new'}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemServiceSubCat(item.serv_cat_cod)
		}
	}
	const postItemDeadline = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/deadline/${item.id ? `update/${item.id}` : 'new'}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/deadline')
		}
	}
	const getItemDeadline = async (id, serv_cod, serv_cat_cod, serv_sub_cat_cod) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/deadline/${id || 0}/${serv_cod || 0}/${serv_cat_cod || 0}/${serv_sub_cat_cod || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setDeadline(json)
	}
	const getAllItemDeadline = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/deadline/all/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setDeadline(json)
	}
	const getWhereItemDeadline = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/deadline/search/${desc}`, 'get')
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		let { data, ...rest } = json
		const group = groupBy(data, 'serv_sub_cat_desc')
		return setDeadline({
			data: group,
			...rest,
		})
	}
	const getItemNotifyDealership = async (id) => {
		setLoading(true)
		setData(null)
		const { url, options } = headerOptions(
			`/notify_dealership/${id || '0'}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getWhereItemNotifyDealership = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/notify_dealership/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getItemNotifiedDealership = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/notify_dealership/notifed', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getWhereItemNotifiedDealership = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/notify_dealership/notifed/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const postItemNotifyDealership = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/notify_dealership/${item.id ? 'update' : 'new'}`,
			'post',
			item
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setNotification(null)
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
	}
	const getItemActivitiesOnSite = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/activities_on_site/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getAllItemTypeConstructions = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_constructions/all/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setTypeConstruction(json)
	}
	const getItemTypeConstructions = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_constructions/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setTypeConstruction(json)
	}
	const getWhereItemTypeConstructions = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_constructions/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setCheckInspection(json)
	}
	const getItemSubTypeConstructions = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/sub_type_constructions/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const deleteItemTypeConstructions = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_constructions/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemTypeConstructions()
		}
	}
	const deleteItemSubTypeConstructions = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/sub_type_constructions/delete/${item.id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemSubTypeConstructions(item.tip_ob_sub_tip_ob)
		}
	}
	const postItemTypeConstructions = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_constructions/${item.id ? `/update/${item.id}` : 'new'}`,
			item.id ? 'put' : 'post',
			item
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/type_constructions')
			return getItemTypeConstructions()
		}
	}
	const getItemServiceConstructions = async ({
		id,
		serv_cat_cod,
		serv_sub_cat_cod,
		serv_tip_obra
	}) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/services_constructions/${id || 0}/${serv_cat_cod || 0}/${serv_sub_cat_cod || 0}/${serv_tip_obra || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getWhereItemServiceConstructions = async ({
		id,
		serv_cat_cod,
		serv_sub_cat_cod,
		serv_tip_obra,
		serv_sub_cat_desc,
	}) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/services_constructions/search/${id || 0}/${serv_cat_cod || 0}/${serv_sub_cat_cod || 0}/${serv_sub_cat_desc || null}/${serv_tip_obra || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const postItemServiceConstructions = async ({
		id,
		serv_cod,
		serv_cat_cod,
		tip_ob_cod,
		ob_serv_med_cod,
		serv_desc,
		serv_sub_cat_desc,
	}) => {
		setLoading(true)
		const payload = {
			serv_cod,
			serv_cat_cod,
			tip_ob_cod,
			ob_serv_med_cod,
			serv_desc,
			serv_sub_cat_desc,
		}
		const { url, options } = headerOptions(
			`/services_constructions/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST', payload
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigate(namesScreens.SERVICES_CONSTRUCTIONS)
		}
	}
	const getItemMeasureService = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/measure_service/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setDeadline(json)
	}
	const getItemWhereMeasureService = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/measure_service/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setDeadline(json)
	}
	const getItemTypeAttch = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/types_attachments/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setContractAttachment(json)
	}
	const postItemConstructionPlan = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/construction_plan/${item.id ? `update/${item.id}` : 'new'}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok && item?.filter) {
			const {
				id_from_filter,
				rod_cod,
				ped_venda_date_init,
				ped_venda_date_fim,
				type_date,
			} = item.filter
			getItemConstructionPlan({
				id: id_from_filter,
				rod_cod,
				ped_venda_date_init,
				ped_venda_date_fim,
				type_date,
			})
			return navigate('/construction_plan')
		}
	}
	const deletetItemConstructionPlan = async (item) => {
		const {
			id,
			id_from_filter,
			rod_cod,
			ped_venda_date_init,
			ped_venda_date_fim,
			type_date,
		} = item
		setLoading(true)
		const { url, options } = headerOptions(
			`/construction_plan/delete/${id}`,
			'DELETE'
		)
		const response = await fetch(url, options)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemConstructionPlan({
				id: id_from_filter,
				rod_cod,
				ped_venda_date_init,
				ped_venda_date_fim,
				type_date,
			})
			return navigate('/construction_plan')
		}
	}
	const getItemProgressConstruction = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/progress_construction/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		if (json?.data?.length) {
			const { data: json_data } = json
			const return_data = json_data
			return return_data
		}
		return null
	}
	const getItemConstructionPlan = async (state) => {
		setLoading(true)
		const { id, rod_cod, ped_venda_date_init, ped_venda_date_fim, type_date, desc = null, see_completed_works = 0 } =
			state
		const { url, options } = headerOptions(
			`/construction_plan/${id || 0}/${rod_cod || 0}/${ped_venda_date_init || null
			}/${ped_venda_date_fim || null}/${desc || null}/${type_date || 8}/${see_completed_works || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setConstructionPlan(json)
	}
	const getItemWhereConstructionPlan = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/construction_plan/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setCardinalPoints(json)
	}
	const getItemAttachmentsConstructionPlan = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/attachments_contruction_plan/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const postItemAttachmentsConstructionPlan = async (state) => {
		setLoading(true)
		console.log('no post anexos', state)
		const { id, pl_ob_cod } = state
		const { url, options } = headerOptions(
			`/attachments_contruction_plan/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (!response.ok) {
			return
		}
		return getItemAttachmentsConstructionPlan(pl_ob_cod)
	}
	const deleteItemAttachmentsConstructionPlan = async (state) => {
		setLoading(true)
		const { id, pl_ob_cod } = state
		const { url, options } = headerOptions(
			`/attachments_contruction_plan/delete/${id}`,
			'DELETE'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemAttachmentsConstructionPlan(pl_ob_cod)
		}
	}

	const postStartConstructionPlan = async (item) => {
		setLoading(true)
		const { rod_cod, ped_venda_date_init, ped_venda_date_fim, type_date, id } =
			item
		const { url, options } = headerOptions(
			`/construction_plan/start/${item.id}`,
			'PUT',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemConstructionPlan({
				id,
				rod_cod,
				ped_venda_date_init,
				ped_venda_date_fim,
				type_date,
			})
		}
	}

	const postItemProgressConstructionPlan = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/progress_construction/update/${item.id}`,
			'PUT',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemServicesConstructionPlan({
				id: item.pvs_pedidovenda,
				apos_data_from: '',
				apos_data_to: ''
			})
		}
		json.ok = response.ok
		setLoading(false)
		return json
	}
	const deleteItemProgressConstructionPlan = async (item) => {
		setLoading(true)
		if (!item?.id) {
			return
		}
		const { url, options } = headerOptions(
			`/progress_construction/delete/${item.id}`,
			'delete',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemServicesConstructionPlan({
				id: item.pvs_pedidovenda,
				apos_data_from: '',
				apos_data_to: '',
			})
			return json
		}
	}
	const getAllItemComplements = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/complement/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setComplements(json)
	}
	const getItemComplements = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/complement/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setComplements(json)
	}
	const postItemComplements = async (state) => {
		const { id } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/complement/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		setLoading(false)
		if (response.ok) {
			navigate('/complements')
			return getItemComplements()
		}
	}
	const getItemWhereComplements = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/complement/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setComplements(json)
	}
	const deleteItemComplements = async (state) => {
		const { id } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/complement/delete/${id}`,
			'DELETE',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		setLoading(false)
		if (response.ok) {
			return getItemComplements()
		}
	}
	const getItemConfigurationParameters = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/configuration_parameters/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getWhereItemConfigurationParameters = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/configuration_parameters/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const postItemConfigurationParameters = async (state) => {
		const { id } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/configuration_parameters/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		setLoading(false)
		if (response.ok) {
			navigate('/configurations_paramaters')
			return getItemConfigurationParameters()
		}
	}
	const deleteItemConfigurationParameters = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/configuration_parameters/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		setLoading(false)
		if (response.ok) {
			return getItemConfigurationParameters()
		}
	}
	const getItemUsers = async (state) => {
		setLoading(true)
		const { set_nivel = 0, set_cod, conc_cod, gp_cod, id, desc } = state
		const { url, options } = headerOptions(
			`/users/${id || 0}/${set_nivel || 0}/${set_cod || 0}/${conc_cod || 0}/${gp_cod || 0}/${desc || null}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setComplements(json)
	}
	const deleteItemUsers = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/users/delete/${id}`, 'delete')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})

		if (response.ok) {
			return getItemUsers({
				id: 0,
				set_nivel: 0,
				set_cod: 0,
				conc_cod: 0,
				gp_cod: 0,
			})
		}
	}
	const postItemUsers = async (state) => {
		setLoading(true)
		const { url, options } = headerOptions('/users/new', 'POST', state)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		const { message, info } = json
		setInfoRequest({
			status: response.status,
			message: `${message} ${info ? info : ''}`,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigate(`${namesScreens.REGISTERS}${namesScreens.USERS}`)
		}
	}
	const updatePassword = async (state) => {
		setLoading(true)
		const { url, options } = headerOptions(
			'/users/update_password',
			'put',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		const { message } = json
		setInfoRequest({
			status: response.status,
			message: message,
			...execptionWithIcons(response.status),
		})
	}
	const getItemReportOversight_ = async (state) => {
		setLoading(true)
		if (!state) {
			return
		}
		const { url, options } = headerOptions(`/report/oversight${state}`, 'get')
		setReport(null)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return setReport(json)
		}
	}
	const getItemAllCompany = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/company/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setCompany(json)
	}
	const getItemCompany = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/company/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setCompany(json)
	}
	const getItemWhereCompany = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/company/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const postItemCompany = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/company/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/company')
			return getItemCompany()
		}
	}
	const deleteItemCompany = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/company/delete/${id}`, 'delete')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemCompany()
		}
	}

	const getItemAllSector = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/sector/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getItemSector = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/sector/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setSector(json)
	}
	const getItemWhereSector = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/sector/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const postItemSector = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/sector/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/sector')
			return getItemSector()
		}
	}
	const deleteItemSector = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/sector/delete/${id}`, 'delete')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemSector()
		}
	}
	const getItemVersion = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/version/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getItemWhereVersion = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/version/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getItemHelp = async (id) => {
		setLoading(true)
		setData(null)
		const { url, options } = headerOptions(`/help/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getWhereItemHelp = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/tutorial/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getItemTutoriais = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/tutorial/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getWhereItemTutoriais = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/tutorial/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const deleteItemTutoriais = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/tutorial/delete/${id}`, 'DELETE')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemTutoriais()
		}
	}
	const postItemHelp = async (state, callback) => {
		setLoading(true)
		const { url, options } = headerOptions('/help/new', 'post', state)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			if (callback) {
				callback()
			}
			return navigate('/tutorial')
		}
	}
	const getItemHighwayLimit = async (state) => {
		setLoading(true)
		const { conc_cod, rod_cod } = state
		const { url, options } = headerOptions(
			`/highway_limit/${state?.id || 0}/${conc_cod || 0}/${rod_cod || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setLimitHighway(json)
	}
	const getAllItemHighwayLimit = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/highway_limit/all/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setLimitHighway(json)
	}
	const getWhereItemHighwayLimit = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/highway_limit/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setLimitHighway(json)
	}
	const postItemHighwayLimit = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/highway_limit/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/highway_limit')
			return getItemHighwayLimit()
		}
	}
	const deleteItemHighwayLimit = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/highway_limit/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemHighwayLimit({
				id: 0,
				conc_cod: 0,
				rod_cod: 0,
			})
		}
	}
	const getAllMenu = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/menu/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setMenus(json)
	}

	const getUserMenus = async (email = '', setNivel = 0) => {
		setLoading(true)
		const { url, options } = headerOptions(
			'/usuariomenu/getbyuseremail/',
			'post',
			{ email: email }
		)
		const response = await fetch(url, options)
		setLoading(false)
		const tempData = await response.json()
		let finalJson
		if (setNivel) {
			finalJson = await tempData?.data?.filter(
				(item) => item.menu_nivelsetNivel
			)
			finalJson = { total: tempData.total, data: [...finalJson] }
		} else {
			finalJson = tempData
		}
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: finalJson.message,
				...execptionWithIcons(response.status),
			})
		}
		if (email !== '') {
			return setEditingMenus(finalJson)
		}
		return setUserMenus(finalJson)
	}

	const updateUserMenus = async (item) => {
		const menus = []
		item?.userMenus?.data.map((item) =>
			item?.selected ? menus.push(item.menu_cod) : null
		)
		setLoading(true)
		const { url, options } = headerOptions(
			'/usuariomenu/updateusermenus/',
			'POST',
			{ email: item.email, menus }
		)
		const response = await fetch(url, options)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getUserMenus()
			return navigate('/users')
		}
	}

	const getAllItemGroups = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/groups/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setGroups(json)
	}
	const getItemGroups = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/groups/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setGroups(json)
	}
	const getWhereItemGroups = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/groups/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const postItemGroups = async (state) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/groups/${state?.id ? `update${state.id}` : 'new'}`,
			state?.id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigate('/groups')
		}
	}
	const deleteItemGroups = async (state) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/groups/${state?.id}`,
			'delte',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemGroups()
		}
	}
	const postSendItemResetPassword = async (state, callback) => {
		setLoading(true)
		const { url, options } = headerOptions(
			'/users/reset_password',
			'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok && callback) {
			return callback()
		}
	}
	const getItemObArtType = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_type/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtType(json)
	}
	const getAllItemObArtType = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/work_of_art_type/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtType(json)
	}
	const getItemObArtElement = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_elem/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtElem(json)
	}
	const getAllItemObArtElement = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/work_of_art_elem/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtElem(json)
	}
	const getWhereItemObArtElement = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_elem/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtElem(json)
	}
	const postItemObArtElement = async (state) => {
		const { id } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_elem/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			{ ...state }
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemObArtType()
			return navigate('/work_of_art_element')
		}
	}
	const deleteItemObArtElement = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_elem/delete/${id}`,
			'DELETE'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemObArtElement()
		}
	}
	const getWhereItemObArtType = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_type/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtType(json)
	}
	const postItemObArtType = async (state) => {
		const { id } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_type/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			{ ...state }
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemObArtType()
			return navigate('/work_of_art_type')
		}
	}
	const deleteItemObArtType = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_type/delete/${id}`,
			'DELETE'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemObArtType()
		}
	}
	const getItemObArtElem = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_elem/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtElem(json)
	}

	const getItemObArtClass = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_class/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtClass(json)
	}
	const getAllItemObArtClass = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/work_of_art_class/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtClass(json)
	}
	const getWhereItemObArtClass = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_class/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArtClass(json)
	}
	const postItemObArtClass = async (state) => {
		const { id } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_class/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			{ ...state }
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemObArtClass()
			return navigate('/work_of_art_class')
		}
	}
	const deleteItemObArtClass = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art_class/delete/${id}`,
			'DELETE'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemObArtClass()
		}
	}
	const getItemObArt = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/work_of_art/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArt(json)
	}

	const getItemWhereObArt = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setObArt(json)
	}

	const postItemObArt = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/work_of_art/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/work_of_art')
			return getItemObArt()
		}
	}
	const deleteItemObArt = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/work_of_art/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemObArt()
		}
	}

	const deleteItemEmail = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/email/delete/${id}`, 'delete')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()

		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		return response.ok
	}
	const getItemUserBatch = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/user_batch/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
	}
	const getAllItemUserBatch = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/user_batch/all/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setUserLimitHighway(json)
	}
	const deletetemUserBatch = async (state) => {
		const { id, user_id } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/user_batch/delete/${id || 0}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getAllItemUserBatch(user_id)
		}
	}
	const getItemGerEm = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/ger_em/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setGerEm(json)
	}

	const getItemWhereGerEm = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(`/ger_em/search/${desc}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setGerEm(json)
	}

	const postItemGerEm = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/ger_em/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/manage_emails')
			return getItemGerEm()
		}
	}

	const reSendGerEm = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/ger_em/resend/${id}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigate('/manage_emails')
		}
	}

	const deleteItemUserBatch = async (state) => {
		const { id, lot_usu_usu } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/user_batch/delete/${id}`,
			'delete',
			{ ...state }
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemUserBatch(lot_usu_usu)
		}
	}

	const deleteItemGerEm = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/ger_em/delete/${id}`, 'delete')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemGerEm()
		}
	}
	const getItemTypeSubServiceCategory = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_service_sub_category/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setGerEm(json)
	}
	const getItemWhereTypeSubServiceCategory = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_service_sub_category/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setGerEm(json)
	}
	const postItemTypeSubServiceCategory = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/type_service_sub_category/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigate('/type_service')
		}
	}
	const deleteItemTypeSubServiceCategory = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_service_sub_category/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemTypeSubServiceCategory()
		}
	}
	const getAllTimeLineOversight = async (state) => {
		setLoading(true)
		const { id, ped_venda_status, ped_venda_date_from, ped_venda_date_to } =
			state
		const { url, options } = headerOptions(
			`/timeline/oversight/${id || 0}/${ped_venda_status || 0}/${ped_venda_date_from || null
			}/${ped_venda_date_to || null}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setOversight(json)
	}
	const postItemTimelineOversight = async (state, callback) => {
		setLoading(true)
		const { id, pv_servico, ...rest } = state
		const { url, options } = headerOptions(
			`/timeline/oversight/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			id
				? {
					...rest,
					pv_servico,
				}
				: pv_servico
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			if (callback) {
				callback()
			}
			return getAllTimeLineOversight({
				id: 0,
				ped_venda_status: 0,
				ped_venda_date_from: '',
				ped_venda_date_to: '',
			})
		}
	}
	const getAllItemSAC = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/sac/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setSac(json)
	}
	const getItemTypeOversight = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/type_oversight/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setCheckInspection(json)
	}
	const getWhereItemTypeOversight = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_oversight/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setCheckInspection(json)
	}

	const deleteItemTypeOversight = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_oversight/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemTypeOversight()
		}
	}
	const postItemTypeOversight = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/type_oversight/${item.id ? `/update/${item.id}` : 'new'}`,
			item.id ? 'put' : 'post',
			item
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/type_oversight')
			return getItemTypeOversight()
		}
	}
	const getItemLocationOversight = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_location/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setOversightLocation(json)
	}
	const getWhereItemLocationOversight = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/oversight_location/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setCheckInspection(json)
	}
	const postItemLocationOversight = async (item) => {
		setLoading(true)
		const { pv_loc_pedidovenda } = item
		const { url, options } = headerOptions(
			`/oversight_location${item.id ? `/update/${item.id}` : 'new'}`,
			item.id ? 'put' : 'post',
			item
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemCheckInspection(pv_loc_pedidovenda)
			return getItemOversighttService(pv_loc_pedidovenda)
		}
	}
	const postItemSyncOversightStatus = async (state) => {
		setLoading(true)
		const { pvs_cod: id } = state
		const { url, options } = headerOptions(
			`/integration_sisf/start/${id}`,
			'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
	}
	const getItemAllSyncStatus = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/sync_status/all', 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setSyncStatus(json)
	}
	const getItemSyncStatus = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/sync_status/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}

		setStatus(json)
	}
	const getItemWhereSyncStatus = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/sync_status/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}

		setStatus(json)
	}
	const postIemSyncStatus = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/sync_status/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/sync_status')
			return getItemSyncStatus()
		}
	}
	const deleteItemSyncStatus = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/sync_status/delete/${id}`,
			'delete'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemSyncStatus()
		}
	}
	const getItemSyncCheckInspection = async (state) => {
		setLoading(true)
		const {
			id,
			sinc_st_cod,
			conc_cod,
			rod_cod,
			ped_venda_date_from,
			ped_venda_date_to,
			type_date,
		} = state
		const { url, options } = headerOptions(
			`/integration_sisf/check_inspection/${id || '0'}/${sinc_st_cod || 0}/${conc_cod || 0
			}/${rod_cod || 0}/${ped_venda_date_from || null}/${ped_venda_date_to || null
			}/${type_date || 0}`,
			'get'
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setCheckInspection(json)
	}
	const postItemSyncStartCheckInspectionOversightStatus = async (state) => {
		setLoading(true)
		const { url, options } = headerOptions(
			'/integration_sisf/check_inspection/start',
			'POST',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		return getItemSyncCheckInspection(state, 'update')
	}

	const postItemTutoriais = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/tutorial/${id ? `update/${id}` : 'new'}`,
			id ? 'put' : 'post',
			state
		)
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			getItemTutoriais()
			return navigate('/tutorial')
		}
	}
	const postItemServiceConstructionPlan = async (item) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/construction_plan/${item.id ? `update/service/${item.id}` : 'service/new'
			}`,
			item.id ? 'PUT' : 'POST',
			item
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (item?.pvs_pedidovenda && response.ok) {
			const { pvs_pedidovenda: ped_venda_cod } = item
			getItemServicesConstructionPlan({
				id: ped_venda_cod,
				apos_data_from: null,
				apos_data_to: null
			})
		}
		return response.ok
	}
	const postApproveCheckcInspection = async (state) => {
		setLoading(true)
		const { ped_venda_cod } = state
		const { url, options } = headerOptions(
			'/check_inspection/approved',
			'POST',
			state
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getAllItemCheckInspection(ped_venda_cod)
		}
	}
	const postApproveProgress = async (state) => {
		setLoading(true)
		const { id } = state
		const { url, options } = headerOptions(
			`/progress_construction/approve_progress/${id}`,
			'POST',
			state
		)

		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok && state?.pvs_pedidovenda) {
			const { pvs_pedidovenda } = state
			getItemServicesConstructionPlan({
				id: pvs_pedidovenda,
				apos_data_from: null,
				apos_data_to: null
			})
		}
		return response.ok
	}
	const getToDownloadDefaultSpreedSheetConstructionPlan = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/construction_plan/default_spreedsheet/download/${id || 0}`,
			'GET'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		setLoading(false)
		if (!response.ok) {
			return {
				file_name: '',
				url: '',
				success: response.ok,
			}
		}
		if (!json?.data?.length) {
			return {
				file_name: '',
				url: '',
				success: false
			}
		}
		const { tip_ob_desc, pmo_path } = json.data[json?.data?.length - 1]
		storageCache(`${namesCache.REQUEST_SPREDSHEET_}${id}`, 'true')
		const file_name = `${tip_ob_desc}.xlsx`
		const url_file = pmo_path
		const responseDownload = await downLoadFiles(url_file, file_name)
		if (!responseDownload) {
			return {
				file_name,
				url: url_file,
				success: response.ok,
			}
		}
		return {
			file_name,
			url: url_file,
			success: response.ok,
		}
	}
	const getItemDefaultSpreedSheetConstructionPlan = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/construction_plan/default_spreedsheet/${id || 0}`,
			'GET'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const getWhereItemDefaultSpreedSheetConstructionPlan = async (desc) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/construction_plan/default_spreedsheet/search/${desc}`,
			'GET'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setData(json)
	}
	const postItemDefaultSpreedSheetConstructionPlan = async (state) => {
		const { id } = state
		setLoading(true)
		const { url, options } = headerOptions(
			`/construction_plan/default_spreedsheet/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			state
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate('/spreedsheet_default')
			return getItemDefaultSpreedSheetConstructionPlan('0')
		}
	}
	const deleteItemDefaultSpreedSheetConstructionPlan = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(
			`/construction_plan/default_spreedsheet/delete/${id || 0}`,
			'DELETE'
		)
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemDefaultSpreedSheetConstructionPlan('0')
		}
	}
	const getAllItemConfig = async () => {
		setLoading(true)
		const { url, options } = await headerOptions(
			'/configuration_parameters/all',
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			return
		}
		storageCache(namesCache.CONFIG, json.data)
	}
	const getItemCalcMemorySpreadSheet = async (id) => {
		setLoading(true)
		const { url, options } = await headerOptions(
			`/construction_plan/default_spreedsheet/download/calc_memory/${id}`,
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return false
		}
		const { data: json_data } = json
		if (!json_data?.length) {
			return false
		}
		const { file_name, base64, serv_sub_cat_desc } = json_data[json_data.length - 1]
		if (!base64) {
			return setInfoRequest(handleWaring('Não foi possível gerar a planilha'))
		}
		return base64toFile(base64, `${serv_sub_cat_desc}-${file_name}`)
	}
	const postToGetItemCompressFile = async ({
		file,
		driver
	}) => {
		setLoading(true)
		const state = { driver, file }
		const { url, options } = await headerOptions(
			'/file_mageneger/compress',
			'POST',
			{ ...state }
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			const { base64 } = json
			const file_name = `Relatório-NC-${formatDate()}.pdf`
			base64toFile(base64, file_name)
		}
		return response.ok
	}
	const getItemPavementIndicators = async ({ id = 0, conc_cod = 0 }) => {
		setLoading(true)
		setCardinalPoints(null)
		const { url, options } = await headerOptions(
			`/pavement_indicators/${id || 0}/${conc_cod || 0}`,
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return setCardinalPoints(null)
		}
		setCardinalPoints(json)
	}
	const getItemAllPavementIndicators = async ({ id = 0, conc_cod = 0 }) => {
		setLoading(true)
		const { url, options } = await headerOptions(
			`/pavement_indicators/full/${id || 0}/${conc_cod || 0}`,
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return null
		}
		return json
	}
	const getWhereItemPavementIndicators = async (desc) => {
		setLoading(true)
		setData(null)
		const { url, options } = await headerOptions(
			`/pavement_indicators/search/${desc}`,
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return setData(null)
		}
		setData(json)
	}
	const getItemPavementIndicatorsGroupsIndex = async ({ id = 0, not_paginate = true }) => {
		setLoading(true)
		setData(null)
		let path = `/pavement_indicators/group_index/${id || 0}`
		if (!not_paginate) {
			path = `/pavement_indicators/group_index/not_paginate/${id || 0}`
		}
		const { url, options } = await headerOptions(
			path,
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return setTypeConstruction(null)
		}
		return setTypeConstruction(json)
	}
	const postItemPavementIndicatorsGroupsIndex = async ({
		id,
		gp_ind_pav_desc
	}) => {
		const state = {
			gp_ind_pav_desc
		}
		setLoading(true)
		const { url, options } = await headerOptions(
			`/pavement_indicators/group_index/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			state
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate(namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX)
			return getItemPavementIndicatorsGroupsIndex({ id: 0 })
		}
	}
	const deleteItemPavementIndicatorsGroupsIndex = async (id) => {
		setLoading(true)
		const { url, options } = await headerOptions(
			`/pavement_indicators/group_index/delete/${id}`,
			'DELETE')
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemPavementIndicatorsGroupsIndex({ id: 0 })
		}
	}
	const getItemTypeIndexPavementIndicators = async ({ id = 0, not_paginate = false }) => {
		setLoading(true)
		setData(null)
		let path = `/pavement_indicators/type_index/${id || 0}`
		if (!not_paginate) {
			path = `/pavement_indicators/type_index/not_paginate/${id || 0}`
		}
		const { url, options } = await headerOptions(
			path,
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return setComplements(null)
		}
		return setComplements(json)
	}
	const getWhereItemTypeIndexPavementIndicators = async (desc) => {
		setLoading(true)
		setData(null)
		const { url, options } = await headerOptions(
			`/pavement_indicators/type_index/search/${desc || 0}`,
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return setComplements(null)
		}
		return setComplements(json)
	}
	const postItemTypeIndexPavementIndicators = async ({
		id,
		tip_ind_pav_desc
	}) => {
		const state = {
			tip_ind_pav_desc
		}
		setLoading(true)
		const { url, options } = await headerOptions(
			`/pavement_indicators/type_index/${id ? `update/${id}` : 'new'}`,
			id ? 'PUT' : 'POST',
			state
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			navigate(namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX)
			return getItemTypeIndexPavementIndicators({ id: 0 })
		}
	}
	const deleteItemTypeIndexPavementIndicators = async (id) => {
		setLoading(true)
		const { url, options } = await headerOptions(
			`/pavement_indicators/type_index/delete/${id}`,
			'DELETE')
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return getItemTypeIndexPavementIndicators({ id: 0 })
		}
	}
	const getItemSpreesheetPavementSegments = async (spreedhseet) => {
		setLoading(true)
		const { url, options } = await headerOptions(
			'/pavement_indicators/spreadsheet',
			'GET',
			spreedhseet)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		let files = []
		if (response.ok) {
			const { data: json_data } = json
			if (json_data?.length) {
				const { url, files: filesOnZip } = json_data[json_data.length - 1]
				downLoadFiles(url)
				files = filesOnZip
			}
		}
		return { success: response.ok, files }
	}
	const postItemPavementSegments = async (state) => {
		const { mon_items, ...rest } = state
		setLoading(true)
		const { url, options } = await headerOptions(
			'/pavement_indicators/new',
			'POST',
			rest
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			const { data: json_data } = json
			if (json_data) {
				const { lipc_cod } = json_data
				return postItemMonitoringSegments({
					mon_pav_lipc_cod: lipc_cod,
					mon_items,
				})
			}
		}
	}
	const postItemMonitoringSegments = async (state) => {
		try {
			setLoading(true)
			const {
				mon_pav_lipc_cod,
				mon_items,
			} = state
			const pageSize = 100
			const pages = paginateToPostApi(mon_items, pageSize)
			const messagesSuccess = []
			const errorMessages = []
			for (const page of pages) {
				try {
					const { url, options } = await headerOptions('/pavement_indicators/monitoring/new', 'POST', {
						mon_pav_lipc_cod,
						mon_items: page
					})
					const response = await fetch(url, options)
					const json = await response.json()
					if (response.ok) {
						messagesSuccess.push(json?.message)
					} else {
						errorMessages.push(json?.message)
					}
				} catch (error) {
					errorMessages.push(String(error))
				}
			}
			setInfoRequest({
				status: 200,
				message: filterAndRemoveRepeated(messagesSuccess).join(','),
				...execptionWithIcons(200)
			})
			setInfoRequest({
				status: 500,
				message: filterAndRemoveRepeated(errorMessages).join(','),
				...execptionWithIcons(500)
			})
			setLoading(false)
			return navigate(namesScreens.PAVEMENT_INDICATOR)
		} catch (error) {
			setInfoRequest({
				status: 500,
				message: 'Não foi possível completar sua solicitação',
				...execptionWithIcons(500)
			})
		}
	}
	const getItemAllMonitoringIndicators = async ({
		id,
		rod_cod,
		rod_concessionaria,
		mon_date_from,
		mon_date_to,
		rod_km_ini_from,
		rod_km_ini_to
	}) => {
		setLoading(true)
		const { url, options } = await headerOptions(
			`/pavement_indicators/monitoring/${id || 0}/${rod_cod || 0}/${rod_concessionaria || 0}/${mon_date_from || null}/${mon_date_to || null}/${rod_km_ini_from || 0}/${rod_km_ini_to || 0}`,
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return null
		}
		return json
	}
	const getItemAllConsolidatedPavementIndex = async ({ id = 0 }) => {
		setLoading(true)
		const { url, options } = await headerOptions(
			`/pavement_indicators/monitoring/consolidated_pavement_index/${id || 0}`,
			'get'
		)
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		if (!response.ok) {
			setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
			return null
		}
		return json
	}
	const getAllItemChecklist = async (id) => {
		setLoading(true)
		const { url, options } = headerOptions(`/checklist/${id || 0}`, 'get')
		const response = await fetch(url, options)
		setLoading(false)
		const json = await response.json()
		if (!response.ok) {
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status),
			})
		}
		setChecklists(json)
	}
	const postChecklistArray = async (state) => {
		setLoading(true)
		const { url, options } = await headerOptions(
			'/checklist_resp/new_array',
			'POST',
			{ data: state })
		const response = await fetch(url, options).finally(() => setLoading(false))
		const json = await response.json()
		setInfoRequest({
			status: response.status,
			message: json.message,
			...execptionWithIcons(response.status),
		})
		if (response.ok) {
			return navigate(namesScreens.PAVEMENT_CHECKLIST_MANAGER)
		}
	}
	const getAllItemEarchWorkSection = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/pavement_indicators/monitoring/earthwork_section', 'GET')
		const response = await fetch(url, options)
		const json = await response.json()
		setLoading(false)
		if (!response.ok) {
			setLimitHighway(null)
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status)
			})
		}
		setLimitHighway(json)
	}
	const getAllItemTypeCoating = async () => {
		setLoading(true)
		const { url, options } = headerOptions('/pavement_indicators/monitoring/type_coating', 'GET')
		const response = await fetch(url, options)
		const json = await response.json()

		setLoading(false)
		if (!response.ok) {
			setUserLimitHighway(null)
			return setInfoRequest({
				status: response.status,
				message: json.message,
				...execptionWithIcons(response.status)
			})
		}
		setUserLimitHighway(json)
	}
	return (
		<requestContext.Provider
			value={{
				data,
				infoRequest,
				highway,
				dealership,
				contract,
				infoaddress,
				lote,
				dealershipLote,
				service,
				servicecat,
				serviceSubcat,
				contract_Attachment,
				cardinal_points,
				Oversightcount,
				status,
				check_inspection,
				oversight,
				googleMapsApiKey,
				service_late,
				loading,
				type_highway,
				notification,
				deadline,
				complements,
				menus,
				userMenus,
				editingMenus,
				report,
				groups,
				company,
				limithigway,
				sac,
				userLimithigway,
				syncStatus,
				oversight_location,
				type_constructions,
				obArtType,
				obArtElem,
				obArtClass,
				obArt,
				gerEm,
				reportData,
				monitoring_progressservice_work,
				monitoring_progress_work,
				monitoring_progress,
				checklists,
				construction_plan,
				sector,
				setSector,
				setConstructionPlan,
				setReportData,
				setContractAttachment,
				setSac,
				setLimitHighway,
				setReport,
				setGroups,
				setComplements,
				setCardinalPoints,
				setDeadline,
				setOversight,
				setData,
				setHighway,
				setDealership,
				setDealershipLote,
				setLote,
				setServicecat,
				setServiceSubcat,
				setService,
				setInfoRequest,
				setContract,
				setStatus,
				setTypeHighway,
				setCheckInspection,
				setLoading,
				setServiceLate,
				setNotification,
				setMenus,
				setUserMenus,
				setSyncStatus,
				setOversightLocation,
				setEditingMenus,
				setTypeConstruction,
				setChecklists,
				getItemOversight,
				getAllItemDealership,
				getItemDealership,
				getWhereItemDealershipContract,
				postItemDealershipContractAttachment,
				deleteItemDealershipContractAttachment,
				getInfoAdress,
				postItemDealership,
				getItemDealershipEmail,
				getItemDealershipContract,
				getItemDealershipContractAttachment,
				getWhereItemDealership,
				postItemDealershipContract,
				getItemHighway,
				getWhereItemHighway,
				getAllItemHighway,
				postItemHighway,
				deleteItemHighway,
				deleteItemDealership,
				getItemService,
				getAllItemService,
				getItemCategoryService,
				getWhereItemService,
				getItemServiceSubCat,
				getAllItemServiceSubCat,
				getAllItemCategoryService,
				getAllItemCardinalPoints,
				postItemOversight,
				postItemOversightService,
				deleteItemOversight,
				getItemOversighttPhotos,
				postItemOversightPhotos,
				getCountOversight,
				getItemOversighttService,
				deleteItemOversightService,
				getItemStatusOversight,
				getItemCheckInspection,
				postItemCheckInspection,
				getItemReportOversight,
				getItemReportOversightMap,
				getAllItemDealershipLot,
				getItemApiKey,
				getItemTypeHigway,
				postItemService,
				deleteItemService,
				deleteItemCategoryService,
				deleteItemCategorySubService,
				postItemCategorySubService,
				postItemDeadline,
				getItemDeadline,
				getWhereItemDeadline,
				getAllItemDeadline,
				getItemNotifyDealership,
				postItemNotifyDealership,
				getItemCardinalPoints,
				getItemNotifiedDealership,
				getWhereItemNotifiedDealership,
				getItemActivitiesOnSite,
				getAllItemTypeConstructions,
				getItemTypeConstructions,
				getWhereItemTypeConstructions,
				getItemSubTypeConstructions,
				deleteItemTypeConstructions,
				deleteItemSubTypeConstructions,
				postItemTypeConstructions,
				getItemMeasureService,
				getItemWhereMeasureService,
				getItemTypeAttch,
				getItemServiceConstructions,
				postItemConstructionPlan,
				getItemProgressConstruction,
				getItemConstructionPlan,
				getItemWhereConstructionPlan,
				getItemAttachmentsConstructionPlan,
				postItemAttachmentsConstructionPlan,
				deleteItemAttachmentsConstructionPlan,
				postStartConstructionPlan,
				postItemProgressConstructionPlan,
				getItemComplements,
				deleteItemComplements,
				getAllItemComplements,
				postItemComplements,
				postItemCardinalPoints,
				deleteItemCardinalPoints,
				getItemUsers,
				deleteItemUsers,
				getItemSector,
				postItemUsers,
				getItemCompany,
				getItemWhereCompany,
				postItemCompany,
				deleteItemCompany,
				getItemAllStatusOversight,
				postIemStatusOversight,
				deleteItemStatusOversight,
				getItemWhereStatusOversight,
				getItemVersion,
				getItemWhereVersion,
				getItemAllCompany,
				postItemHelp,
				getItemHighwayLimit,
				getWhereItemHighwayLimit,
				postItemHighwayLimit,
				deleteItemHighwayLimit,
				getItemWhereComplements,
				getWhereItemNotifyDealership,
				getAllMenu,
				getUserMenus,
				updateUserMenus,
				getItemHelp,
				getWhereItemHelp,
				getItemTutoriais,
				getItemReportOversight_,
				getAllItemGroups,
				getItemGroups,
				getWhereItemGroups,
				postItemGroups,
				deleteItemGroups,
				postSendItemResetPassword,
				getItemAllSector,
				getItemWhereSector,
				postItemSector,
				deleteItemSector,
				setObArtType,
				getItemObArtType,
				setObArtElem,
				getItemObArtElem,
				setObArtClass,
				getItemObArtClass,
				setObArt,
				getItemObArt,
				getItemWhereObArt,
				postItemObArt,
				deleteItemObArt,
				deleteItemOversightPhotos,
				setGerEm,
				getItemGerEm,
				getItemWhereGerEm,
				postItemGerEm,
				deleteItemGerEm,
				reSendGerEm,
				deleteItemEmail,
				getItemUserBatch,
				getAllItemUserBatch,
				deletetemUserBatch,
				deleteItemUserBatch,
				getItemTypeSubServiceCategory,
				getItemWhereTypeSubServiceCategory,
				postItemTypeSubServiceCategory,
				deleteItemTypeSubServiceCategory,
				getAllItemServiceTimeLine,
				postItemTimelineOversight,
				getAllTimeLineOversight,
				getAllItemCheckInspection,
				postItemObArtType,
				getWhereItemObArtType,
				getAllItemObArtType,
				deleteItemObArtType,
				postItemObArtClass,
				getWhereItemObArtClass,
				deleteItemObArtClass,
				getAllItemObArtClass,
				getAllItemHighwayLimit,
				getWhereItemTutoriais,
				deleteItemTutoriais,
				getItemServicesConstructionPlan,
				getAllItemSAC,
				deleteItemProgressConstructionPlan,
				getItemDERService,
				getItemConfigurationParameters,
				getWhereItemConfigurationParameters,
				postItemConfigurationParameters,
				deleteItemConfigurationParameters,
				getItemTypeOversight,
				getWhereItemTypeOversight,
				postItemTypeOversight,
				deleteItemTypeOversight,
				getItemLocationOversight,
				getWhereItemLocationOversight,
				postItemLocationOversight,
				getItemSyncStatus,
				getItemWhereSyncStatus,
				postIemSyncStatus,
				deleteItemSyncStatus,
				getItemAllSyncStatus,
				postItemSyncOversightStatus,
				getItemSyncCheckInspection,
				postItemSyncStartCheckInspectionOversightStatus,
				updatePassword,
				postItemTutoriais,
				getItemObArtElement,
				getWhereItemObArtElement,
				postItemObArtElement,
				deleteItemObArtElement,
				getAllItemObArtElement,
				deletetItemConstructionPlan,
				postItemServiceConstructionPlan,
				postApproveCheckcInspection,
				getToDownloadDefaultSpreedSheetConstructionPlan,
				getItemDefaultSpreedSheetConstructionPlan,
				getWhereItemDefaultSpreedSheetConstructionPlan,
				postItemDefaultSpreedSheetConstructionPlan,
				deleteItemDefaultSpreedSheetConstructionPlan,
				getAllItemConfig,
				getItemCalcMemorySpreadSheet,
				postToGetItemCompressFile,
				postApproveProgress,
				getItemMonitoringProgressServiceWork,
				getItemMonitoringProgressWork,
				getItemMonitoringProgress,
				postItemMonitoringProgress,
				getItemPavementIndicators,
				getWhereItemPavementIndicators,
				deleteItemPavementIndicatorsGroupsIndex,
				getWhereItemTypeIndexPavementIndicators,
				postItemTypeIndexPavementIndicators,
				deleteItemTypeIndexPavementIndicators,
				getItemSpreesheetPavementSegments,
				postItemPavementSegments,
				getItemPavementIndicatorsGroupsIndex,
				getItemTypeIndexPavementIndicators,
				postItemPavementIndicatorsGroupsIndex,
				getAllItemChecklist,
				postChecklistArray,
				getAllItemEarchWorkSection,
				getAllItemTypeCoating,
				getItemAllPavementIndicators,
				getItemAllMonitoringIndicators,
				getItemAllConsolidatedPavementIndex,
				getWhereItemServiceConstructions,
				postItemServiceConstructions
			}}
		>
			{children}
		</requestContext.Provider>
	)
}
export default RequestContext
