import React from 'react';
import { formatDate } from '../../../../services/dateHandless';
import ListServiceOversightReport from '../../../../components/list/oversightServicesReport';
import ImageItem from '../../../../components/list/img';
import { styleImage } from '../../../../css/globlalStyle';
const RenderItem = ({
    handleEdit,
    generateReport,
    item,
    handleOpenModalFisc,
    allowAddReturn }
) => {
    const handleAttach = () => {
        if (handleOpenModalFisc) {
            handleOpenModalFisc(item)
        }
    }
    return (
        <>
            <div className='card mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className="col-md-2 col-sm-4">
                            <span className="text-secondary">NC:</span>{" "}
                            {item.pedido_venda.sisf_sinc_id || item.pedido_venda.ped_venda_cod}  (Nº rastreio: {item.pedido_venda.ped_venda_cod})
                        </div>
                        <div className="col-md-3 col-sm-8 mb-3">
                            <span className="text-secondary">
                                Concessionária:{" "}
                            </span>{" "}
                            {item.pedido_venda.conc_nome} Lote{" "}
                            {item.pedido_venda.lot_conc_desc}
                        </div>
                        <div className="col-md-5 col-sm-8 mb-3">
                            <span className="text-secondary">Para rodivia: </span>{" "}
                            {item.pedido_venda.rod_km} {item.pedido_venda.rod_desc}
                        </div>
                        {item.pedido_venda.ped_venda_date ? (
                            <div className="col-md-2 col-sm-4 mb-3">
                                <span className="text-secondary">No dia: </span>{" "}
                                {formatDate(item.pedido_venda.ped_venda_date)}
                            </div>
                        ) : null}
                    </div>
                    {item?.pedido_venda_servico?.length ? (
                        <div className='row'>
                            <div className='col-md-11 col-sm-10'>
                                <ListServiceOversightReport
                                    generateReport={generateReport}
                                    data={item.pedido_venda_servico}
                                />
                            </div>
                            {handleEdit ? (
                                <div className='col-md-1 col-sm-12' onClick={() => handleEdit(item)}>
                                    <button className='btn btn-sm btn-info'>
                                        <i className='fa fa-edit'></i>
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    <div className='row'>
                        {item?.pedido_venda_servico?.length
                            ? item.pedido_venda_servico.map((pv_servico) =>
                                pv_servico.pedido_venda_servico_foto.map(
                                    (el, i) => (
                                        <div className='col-md-4 col-sm-12' key={i}>
                                            <ImageItem
                                                legend={`${el.pvs_loc_foto_legenda} referente a ${pv_servico.pvs_obs}`}
                                                src={
                                                    el.pvs_loc_foto_path
                                                        ? el.pvs_loc_foto_path
                                                        : el.base64
                                                }
                                                item={el}
                                            />
                                        </div>
                                    )
                                )
                            )
                            : <p>
                                Não há imagens para serem exibidas
                            </p>}
                        {allowAddReturn !== item.pedido_venda.ped_venda_status ? (
                            <div className="col-md-4 col-sm-12" onClick={handleAttach}>
                                <div className="card text-white bg-dark mb-3 rounded-4" style={{
                                    ...styleImage,
                                    cursor: 'pointer'
                                }}>
                                    <div className="card-body text-center">
                                        <i className='fa fa-camera fa-10x'></i>
                                        <p className='text-card'>
                                            Anexar uma foto de retorno
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <hr />
        </>
    )
}
export default RenderItem