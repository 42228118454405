import React, { useContext, useEffect, useState } from 'react'
import RenderTH from './RenderTH'
import RenderSumTH from './RenderSumTH'
import { formContext } from '../../../context/formContext'
import { filter } from '../../../services/arrayHandless'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../constants'
const ListServiceConstructionPlan = ({ viewMonitoring, items }) => {
	const [services, setServices] = useState(null)
	const { state, onChange } = useContext(formContext)
	const [placeholder, setPlaceHolder] = useState('')
	const [enabledEdit, setEnabledEdit] = useState(true)
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	const [fixedHeaderTable, setFixedHeaderTable] = useState(false)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				setFixedHeaderTable(false)
				return setDisabled(true)
			}
			setFixedHeaderTable(true)
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const {
		serv_sub_cat_desc
	} = state
	useEffect(() => {
		const controllEdit = () => {
			if (viewMonitoring) {
				return setEnabledEdit(false)
			}
			return setEnabledEdit(true)
		}
		controllEdit()
	}, [viewMonitoring])
	useEffect(() => {
		const setItems = () => {
			if (items?.length) {
				const itemsWithCodigo = items.map(item => ({
					...item,
					codigo: parseInt(String(item.serv_sub_cat_subitem_der).substring(7, item?.serv_sub_cat_subitem_der?.length))
				}))
				setServices(itemsWithCodigo)
				const firstItems = items[0]
				setPlaceHolder(`Procurar por serviço que está na disciplina ${String(firstItems?.serv_desc)}`)
			}
		}
		setItems()
	}, [items])
	const handleSearch = () => {
		if (serv_sub_cat_desc?.length > 3) {
			const { data } = filter(items, 'serv_sub_cat_desc', serv_sub_cat_desc)
			if (data?.length) {
				return setServices(data)
			}
			const serv_sub_cat_subitem_der = serv_sub_cat_desc
			const { data: filteredData } = filter(items, 'serv_sub_cat_subitem_der', serv_sub_cat_subitem_der)
			if (filteredData?.length) {
				return setServices(filteredData)
			}
		}
		return setServices(items)
	}
	if (!Array.isArray(items)) {
		return <p className="text-secondary">Não há serviços para serem listados</p>
	}
	return (
		<div className="container-fluid">
			{!disabled ? (
				<div className="form-group pull-right">
					<div className="input-group input-group-sm">
						<input type="text" className="form-control float-right"
							name="serv_sub_cat_desc" value={serv_sub_cat_desc} onChange={onChange}
							placeholder={placeholder} />
						<div className="input-group-append">
							<button className="btn btn-default" onClick={handleSearch}>
								<i className="fas fa-search"></i>
							</button>
						</div>
					</div>
				</div>
			) : ''}
			<div className="table-responsive">

				<div className={!fixedHeaderTable ? '' : 'table_fixed_box'}>
					<table className="table table-bordered">
						<thead className={!fixedHeaderTable ? '' : 'sticky-top top-0'}>
							{Array.isArray(services) ? (
								<RenderSumTH items={services} viewMonitoring={viewMonitoring} />
							) : ''}
							<tr>
								{!viewMonitoring ? (
									<th scope="col">N° Obra</th>
								) : null}
								<th scope="col" colSpan={!viewMonitoring ? 1 : 2}>Código</th>
								{viewMonitoring ? null : (
									<th scope="col">
										Situação
									</th>
								)}
								<th scope="col">Descrição</th>
								<th scope="col">Qtd. Prev.</th>
								<th scope="col">Qtd. Exec.</th>
								<th scope="col">Qtd. Exec. Ant.</th>
								<th scope="col">Progresso %</th>
								<th scope="col">
									Ac. Ant.%
								</th>
								<th scope="col">Ac. Atual %</th>
								{!disabled ? (
									<th scope="col">
										{viewMonitoring ? 'Quando' : ''}
									</th>
								) : ''}
							</tr>
						</thead>
						<tbody>
							{Array.isArray(services) ? services.sort((a, b) => a.codigo - b.codigo).sort((a, b) => a.serv_sub_cat_desc - b.serv_sub_cat_desc).map((item) => (
								<RenderTH key={item.prog_serv_cod} item={item} enabledEdit={enabledEdit} />
							)) : ''}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}
export default ListServiceConstructionPlan