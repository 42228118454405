import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../../constants'
import { formContext } from '../../../../context/formContext'
const RenderSumTH = ({ items, viewMonitoring }) => {
	const { state: { pl_ob_desc_object } } = useContext(formContext)
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const [sum, setSum] = useState({
		pvs_qtd: 0,
		prog_serv_qtd_acul: 0,
		prog_serv_percen_progress: 0,
		prog_serv_percen_acul_ant: 0,
		prog_serv_percen_acul: 0,
	})
	const [state, setState] = useState(null)
	useEffect(() => {
		const calcSum = () => {
			if (!Array.isArray(items)) {
				return
			}
			const firstItems = items[0]
			const { prog_serv_obra, serv_desc } = firstItems
			const findObjectDesc = Array.isArray(pl_ob_desc_object) ? pl_ob_desc_object.find(pl => pl.pl_ob_cod === prog_serv_obra) : { pl_ob_desc_object }
			setState({
				serv_desc,
				...findObjectDesc
			})
			if (viewMonitoring) {
				return
			}
			const pvs_qtd = items.reduce((previous, currentValues) => previous + currentValues.pvs_qtd, 0)
			const prog_serv_qtd_acul = items.reduce((previous, currentValues) => previous + currentValues.prog_serv_qtd_acul, 0)
			const prog_serv_percen_progress = items.reduce((previous, currentValues) => previous + currentValues.prog_serv_percen_progress, 0)
			const prog_serv_percen_acul_ant = items.reduce((previous, currentValues) => previous + currentValues.prog_serv_percen_acul_ant, 0)
			const prog_serv_percen_acul = items.reduce((previous, currentValues) => previous + currentValues.prog_serv_percen_acul, 0)
			setSum({
				pvs_qtd,
				prog_serv_qtd_acul,
				prog_serv_percen_progress,
				prog_serv_percen_acul_ant,
				prog_serv_percen_acul,
			})
		}
		calcSum()
	}, [items, pl_ob_desc_object, viewMonitoring])
	return (
		<tr>
			<th scope="row" className='text-center' colSpan="3">
				{state?.serv_desc}
			</th>
			<td colSpan={state?.pl_ob_desc_object ? 4 : 3}>{state?.pl_ob_desc_object}</td>
			{sum.pvs_qtd > 0 ? <td>{parseFloat(sum.prog_serv_percen_progress).toFixed(2)}% </td> : null}
			{sum.pvs_qtd > 0 ? <td>{parseFloat(sum.prog_serv_percen_acul_ant).toFixed(2)}% </td> : null}
			{sum.pvs_qtd > 0 ? <td>{parseFloat(sum.prog_serv_percen_acul).toFixed(2)}% </td> : null}
			{!disabled ? (
				<td></td>
			) : ''}
		</tr>
	)
}
export default RenderSumTH