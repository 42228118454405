import React from 'react'
import { VERSION } from '../../config/flavor'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
        <footer className='main-footer'>
            <strong>Copyright &copy; 20202 <Link to='#'>Ds Web</Link>.</strong>
            <span className='mr-2'>Todos os direitos reservados</span>
            <div className='float-right d-none d-sm-inline-block'>
                <b>Versão</b> {VERSION}
            </div>
        </footer>
    )
}
export default Footer