import { isValid } from '../isValid'
import { randomNumber } from '../randomId'
import { cleanString, convertToTitleCase, formatString, splitString } from '../stringHandless'

/* eslint-disable radix */
/**
 * Filters an array of objects based on a specified property and value(s).
 *
 * @param {Array<Object>} array - The array of objects to be filtered.
 * @param {string} params - The property of each object to be used for filtering.
 * @param {string|number|Array<number>} id - The value or values to match during the filtering process.
 *   - If `id` is a string, it performs a case-insensitive strict equality check.
 *   - If `id` is a number, it performs a strict equality check after converting both values to integers.
 *   - If `id` is an array of numbers, it filters objects where the property matches any value in the array.
 * @param {Object} [rest] - Additional properties to include in the result object.
 * @returns {Object} - An object containing the filtered data, total count, and additional properties.
 *   - `data`: The array of filtered objects.
 *   - `total`: The total count of filtered objects.
 *   - Additional properties from the `rest` parameter.
 */
const filter = (array, params, id, rest) => {
	if (!array || !params || !id) {
		return {
			data: [],
			total: 0,
			...rest,
		}
	}
	const split = splitString(params, '.')
	const typeOf = typeof id
	if (typeOf === 'string') {
		if (split?.firstPart && split?.secondPart) {
			const filtered = array.filter(
				el => String(el[split.firstPart][split.secondPart]).toLowerCase() === String(id).toLowerCase(),
			)
			return {
				data: filtered,
				total: filtered.length,
				...rest,
			}
		}
		const filtered = array.filter(
			el => String(el[params]).toLowerCase() === String(id).toLowerCase(),
		)
		return {
			data: filtered,
			total: filtered.length,
			...rest,
		}
	}
	if (typeOf === 'boolean') {
		if (split?.firstPart && split?.secondPart) {
			const filtered = array.filter(
				el => el[split.firstPart][split.secondPart] === id,
			)
			return {
				data: filtered,
				total: filtered.length,
				...rest,
			}
		}
		const filtered = array.filter(
			el => el[params] === id,
		)
		return {
			data: filtered,
			total: filtered.length,
			...rest,
		}
	}
	if (Array.isArray(id)) {
		if (split?.firstPart && split?.secondPart) {
			const filtered = array.filter(item => id.some(el => el === item[split.firstPart][split.secondPart]))
			return {
				data: filtered,
				total: filtered.length,
				...rest,
			}
		}
		const filtered = array.filter(item => id.some(el => el === item[params]))
		return {
			data: filtered,
			total: filtered.length,
			...rest,
		}
	}
	if (split?.firstPart && split?.secondPart) {
		const filtered = array.filter(el => parseInt(el[split.firstPart][split.secondPart]) === parseInt(id))
		return {
			data: filtered,
			total: filtered.length,
			...rest,
		}
	}
	const filtered = array.filter(el => parseInt(el[params]) === parseInt(id))
	return {
		data: filtered,
		total: filtered.length,
		...rest,
	}
}
/**
 * Filters an array of objects based on multiple properties and values.
 *
 * @param {Array<Object>} array - The array of objects to be filtered.
 * @param {string} params - The property of each object to be used for the first filtering condition.
 * @param {number} id - The value to match for the first filtering condition.
 * @param {string} cod_params - The property of each object to be used for the second filtering condition.
 * @param {string|number} cod - The value to match for the second filtering condition.
 * @param {Object} [rest] - Additional properties to include in the result object.
 * @returns {Object} - An object containing the filtered data, total count, and additional properties.
 *   - `data`: The array of filtered objects.
 *   - `total`: The total count of filtered objects.
 *   - Additional properties from the `rest` parameter.
 */
const filterMoreOne = (array, params, id, cod_params, cod, rest) => {
	if (!array || !params || !id || !cod_params || !cod) {
		return {
			data: [],
			total: 0,
			...rest,
		}
	}
	const filtered = array.filter(
		el =>
			parseInt(el[params]) === parseInt(id, 10) &&
			parseInt(el[cod_params]) === parseInt(cod),
	)
	return {
		data: filtered,
		total: filtered.length,
		...rest,
	}
}
const filterWord = (array, params, word, rest) => {
	if (!array || !params || !word) {
		return {
			data: [],
			total: 0,
		}
	}
	const filtered = array.filter(
		el => String(el[params]).toLowerCase() === String(word).toLowerCase(),
	)
	return {
		data: filtered,
		total: filtered.length,
		rest,
	}
}

const filterBetween = (array, params, lower, upper, rest) => {
	if (!array || !params || !lower || !upper) {
		return {
			data: [],
			total: 0,
			...rest,
		}
	}
	const filtered = array.filter(
		el => el[params] >= lower && el[params] <= upper,
	)
	return {
		data: filtered,
		total: filtered.length,
		...rest,
	}
}
/**
 * Filter and remove elements from an array based on given parameters.
 *
 * @param {Array} array - The array to filter.
 * @param {string} params - The parameter to filter by.
 * @param {string|number} id - The value to filter out.
 * @param {Object} [rest] - Additional properties to include in the result object.
 * @returns {Object} An object containing the filtered data, total count, and additional properties.
 */
const filterAndRemove = (array, params, id, rest) => {
	if (!array || !params || !id) {
		return {
			data: [],
			total: 0,
			...rest,
		}
	}
	const typeOf = typeof id
	if (typeOf === 'string') {
		const filtered = array.filter(
			el => el[params]?.toLowerCase()?.trim() !== id?.toLowerCase()?.trim(),
		)
		return {
			data: filtered,
			total: filtered.length,
			...rest,
		}
	}
	const filtered = array.filter(el => Number(el[params]) !== Number(id))
	return {
		data: filtered,
		total: filtered.length,
		...rest,
	}
}
/**
 * Finds an item in the array based on specified parameters and value.
 *
 * @function
 * @param {Array<Object>} array - The array to search for the item.
 * @param {string} params - The property of the items to compare with the value.
 * @param {*} value - The value to find within the specified property.
 * @param {Object} rest - Additional properties to include in the result.
 * @returns {Object} - An object containing information about the found item.
 *   - `data`: The found item or `false` if not found.
 *   - `total`: The total number of items found (either 0 or 1).
 *   - Additional properties specified in the `rest` parameter.
 * @example
 * const result = find(myArray, 'name', 'John', { additionalProp: 'someValue' });
 * console.log(result);
 */
const find = (array, params, value, rest) => {
	if (!array || !params || !value) {
		return {
			data: false,
			total: 0,
			...rest,
		}
	}
	const split = splitString(params, '.')
	const typeOf = typeof value
	if (typeOf === 'string') {
		if (split?.firstPart && split.secondPart) {
			const filtered = array.find(
				el => String(el[split?.firstPart][split?.secondPart])?.toLowerCase()?.trim() === String(value)?.toLowerCase()?.trim(),
			)
			return {
				data: filtered,
				total: 1,
				...rest,
			}
		}
		const filtered = array.find(
			el => String(el[params])?.toLowerCase()?.trim() === String(value)?.toLowerCase()?.trim(),
		)
		return {
			data: filtered,
			total: 1,
			...rest,
		}
	}
	if (split?.firstPart && split?.secondPart) {
		const filtered = array.find(el => el[split?.firstPart][split?.secondPart] === value)
		return {
			data: filtered,
			total: 1,
			...rest,
		}

	}
	const filtered = array.find(el => el[params] === value)
	return {
		data: filtered,
		total: 1,
		...rest,
	}

}
const isExist = (array, params, value, comparator) => {
	if (!array || !params || !value) {
		return false
	}
	let filtered
	switch (comparator) {
		case 'equal':
			filtered = array.find(el => el[params] === value)
			break
		case 'different':
			filtered = array.find(el => el[params] !== value)
			break
		default:
			filtered = false
			break
	}
	if (!filtered) {
		return false
	}
	return true
}
const filterValuesUnic = (arr, property) => {
	const uniqueValues = {}
	return arr.filter(obj => {
		if (!uniqueValues[obj[property]]) {
			uniqueValues[obj[property]] = true
			return true
		}
		return false
	})
}

/**
 * Sorts an array of objects based on a specified key and sort type.
 *
 * @param {Array} items - The array of objects to be sorted.
 * @param {Object} sort - The sorting configuration object with properties: key (string) and type ('ascending' | 'descending').
 * @returns {Array} A new array containing objects sorted based on the specified key and sort type.
 *
 * @example
 * const items = [
 *   { id: 1, name: 'John', age: 30 },
 *   { id: 2, name: 'Maria', age: 25 },
 *   { id: 3, name: 'Carlos', age: 40 }
 * ];
 *
 * const sortedData = sortItems(items, { key: 'age', type: 'ascending' });
 * console.log(sortedData);
 * // Output: [
 * //   { id: 2, name: 'Maria', age: 25 },
 * //   { id: 1, name: 'John', age: 30 },
 * //   { id: 3, name: 'Carlos', age: 40 }
 * // ]
 */
const sortItems = async (items, sort) => {
	if (!sort || !sort.key || !sort.type) {
		console.error('Invalid sort configuration')
		return items
	}
	const { type, key } = sort
	const sortOrder = type === 'ascending' ? 1 : type === 'descending' ? -1 : 0

	if (sortOrder !== 0) {
		const split = splitString(key, '.')
		if (split?.firstPart && split?.secondPart) {
			const sortedItems = items.sort((a, b) =>
				a[split?.firstPart][split?.secondPart] >
					b[split?.firstPart][split?.secondPart]
					? sortOrder
					: b[split?.firstPart][split?.secondPart] >
						a[split?.firstPart][split?.secondPart]
						? -sortOrder
						: 0,
			)
			return sortedItems
		}
		const sortedItems = items.sort((a, b) =>
			a[key] > b[key] ? sortOrder : b[key] > a[key] ? -sortOrder : 0,
		)
		return sortedItems
	} else {
		console.error('Invalid sort type')
		return items
	}
}
/**
 * Groups an array of objects by a specific property.
 *
 * @param {Array} array - The array of objects to be grouped.
 * @param {string} key - The property by which objects will be grouped.
 * @returns {Object} An object where the keys are the unique values of the property
 * and the values are arrays containing the corresponding objects.
 *
 * @example
 * const data = [
 * { id: 1, category: 'A', value: 10 },
 * { id: 2, category: 'B', value: 20 },
 * { id: 3, category: 'A', value: 30 },
 * ];
 *
 * const groupByCategory = groupBy(data, 'category');
 * console.log(groupedByCategory);
 * // Expected output:
 * // {
 * // 'A': [{ id: 1, category: 'A', value: 10 }, { id: 3, category: 'A', value: 30 }],
 * // 'B': [{ id: 2, category: 'B', value: 20 }],
 * // }
 */
const groupBy = (array, key) => {
	const split = splitString(key, '.')
	if (split?.firstPart && split?.secondPart) {
		return array.reduce(
			(acc, item) => ({
				...acc,
				[item[split?.firstPart][split?.secondPart]]: [
					...(acc[item[split?.firstPart][split?.secondPart]] ?? []),
					item,
				],
			}),
			{},
		)
	}
	return array.reduce(
		(acc, item) => ({
			...acc,
			[item[key]]: [...(acc[item[key]] ?? []), item],
		}),
		{},
	)
}
/**
 * Filters and removes repeated items from an array based on their string representation.
 *
 * @param {Array<*>} data - The array from which repeated items should be removed.
 * @returns {Array<*>|null} - A new array with repeated items removed, or null if the input array is falsy.
 * @example:
 * const uniqueArray = filterAndRemoveRepeated([1, 2, 2, 3, 4, 4, 5]);
 * console.log(uniqueArray); // [1, 2, 3, 4, 5] 
*/
const filterAndRemoveRepeated = (data) => {
	/**
	 * Object used to track unique items based on their string representation.
	 * @type {Object}
	 */
	const uniqueItemsMap = Object.create(null)

	if (!data) {
		return null
	}

	/**
	 * Filter function to check for and remove repeated items.
	 *
	 * @param {*} a - The current item being processed in the array.
	 * @returns {boolean} - True if the item is unique, false if it is a repeated item.
	 * @this {Object} - The context object used to track unique items.
	 */
	const filterFunction = function (a) {
		const stringRepresentation = JSON.stringify(a)

		// Check if the item is unique based on its string representation
		return !this[stringRepresentation] && (this[stringRepresentation] = true)
	}

	return data.filter(filterFunction, uniqueItemsMap)
}

/**
 * Filtra um array de objetos removendo itens repetidos com base em uma propriedade específica.
 *
 * @param {Object} params - Parâmetros da função.
 * @param {Array} params.array - O array de objetos a ser filtrado.
 * @param {string} params.props - A propriedade por qual os itens repetidos serão removidos.
 * @returns {Array} - O array filtrado sem itens repetidos com base na propriedade especificada.
 *
 * @example
 * const arrayDeObjetos = [...]; // Seu array de objetos
 * const resultado = filterAndRemoveRepeatedByProps({ array: arrayDeObjetos, props: 'nome' });
 */
const filterAndRemoveRepeatedByProps = ({
	array, props
}) => {
	if (array.length === 0) {
		return array // Retorna o array original se estiver vazio
	}
	const valoresVistos = new Set()
	const resultado = []
	const split = splitString(props, '.')
	array.forEach(object => {
		let value = ''
		if (split.firstPart && split?.secondPart) {
			value = object[split.firstPart][split.secondPart]
		} else {
			value = object[props]
		}
		if (!valoresVistos.has(value)) {
			// Adiciona o value ao conjunto para rastrear repetições
			valoresVistos.add(value)
			// Inclui o object no resultado
			resultado.push(object)
		}
	})

	return resultado
}

/**
 * Calculates oversight information based on specified items and a calculation base field.
 *
 * @function
 * @param {Object} items - An object containing groups of items.
 * @param {string} element - The element or group key for which oversight is calculated.
 * @param {Array<Object>} allItems - An array containing all items for the calculation.
 * @param {string} [calculationBaseField='serv_sub_cat_valor'] - The field used as the base for the calculation.
 * @returns {Object} - An object containing oversight information.
 *   - `totalValor`: The total value calculated from all items.
 *   - `totalByGroup`: An array of objects with oversight information for each item group.
  * @example
  * const oversightInfo = calcItemsOversight(myItems, 'group1', allItems);
  * console.log(oversightInfo); 
*/
const calcItemsOversight = (
	items,
	element,
	allItems,
	calculationBaseField = 'serv_sub_cat_valor'
) => {
	if (!items || !element || !allItems) {
		return {
			totalByGroup: [],
			totalValor: 0,
		}
	}

	/**
	 * Array of objects containing oversight information for each item group.
	 * @type {Array<Object>}
	 */
	let totalByGroup = []

	/**
	 * Total value calculated from all items.
	 * @type {number}
	 */
	let totalValor = 0

	// Calculate total value from all items
	totalValor = allItems
		.map((item) => ({
			valor: item[calculationBaseField] * (item.serv_sub_cat_qtd ? item.serv_sub_cat_qtd : item.pvs_qtd),
		}))
		.reduce((a, b) => a + b.valor, 0)

	// Calculate oversight information for each item group
	totalByGroup = items[element].map((item) => {
		const serv_valor = items[element]
			.map((el) => ({
				valor: el[calculationBaseField] * el.serv_sub_cat_qtd,
			}))
			.reduce((a, b) => a + b.valor, 0)

		return {
			serv_desc: item.serv_desc,
			serv_valor,
		}
	})

	return {
		totalValor,
		totalByGroup,
	}
}
/**
 * Verifies the length of an array and returns a message based on the length.
 * If the array is not provided or is not an array, it returns 'No records'.
 *
 * @param {Object} array - The array object to be checked.
 * @param {Array} array.data - The array whose length needs to be verified.
 * @param {number} array.total - The total number of items in the array.
 * @returns {string} - A message indicating the number of records found or an indication of no records found.
 *
 * @example
 * const result = verifyLength({ data: [], total: 0 });
 * console.log(result); // Output: 'No results'
 */
const verifyLength = (array) => {
	if (!Array.isArray(array?.data)) {
		return 'Sem registros'
	}
	const length = array?.total
	if (length === 1) {
		return `${array.total} registro encontrado`
	}
	if (length > 1) {
		return `${array.total} registros encontrados`
	}
	if (length === 0) {
		return 'Sem resutados'
	}
}
/**
 * Verifica se uma string contém uma palavra-chave.
 * @param {string} str - A string para verificar.
 * @param {string} keyword - A palavra-chave a ser procurada na string.
 * @returns {boolean} Retorna true se a palavra-chave for encontrada na string, caso contrário, retorna false.
 */
function containsKeyword(str, keyword) {
	try {
		return str.includes(keyword)
	} catch (error) {
		console.log(error)
		return false
	}
}
/**
 * Faz um push de um elemento em um índice específico de um array.
 * @param {Array} array - O array onde fazer o push.
 * @param {*} item - O elemento a ser adicionado.
 * @param {number} index - O índice onde fazer o push do elemento.
 */
function pushAtIndex(array, item, index) {
	// Primeiro, remove 0 elementos a partir do índice especificado
	// e adiciona o item fornecido ao array na posição especificada.
	array.splice(index, 0, item)
}


/**
 * Refactors the array of rows with services.
 * @param {Array<Array<any>>} rows - The array of rows with services.
 * @returns {Array<Object>} The refactored array of items.
 */
const refactorRowsWithServices = (rows) => {
	const newItems = []
	let serv_desc = ''
	let serv_fase = ''
	for (const item of rows) {
		try {
			if (item.length < 3) {
				continue
			}
			const keyword = 'CÓDIGO DA FASE'
			const find = item.find(row => containsKeyword(row, keyword))
			if (find) {
				const generate_keys_name_fase = item.map(row => ({ key: row }))
				const valid = isValid(generate_keys_name_fase)
				if (!valid) {
					continue
				}
				const temp_fase = cleanString(item[0])
				serv_fase = parseInt(temp_fase)
				serv_desc = formatString(item[1])
				continue
			}
			if (item.length >= 7) {
				continue
			}
			const findbycodsubitemder = cleanString(item[0])
			if (!findbycodsubitemder) {
				pushAtIndex(item, serv_fase, 0)
				// item[0] = serv_fase
			}
			const generate_keys = item.map(row => ({ key: row }))
			const valid = isValid(generate_keys)
			if (!valid) {
				continue
			}
			const serv_sub_cat_subitem_der = String(item[0]).toUpperCase()
			const serv_sub_cat_desc = convertToTitleCase(item[1])
			const ob_serv_med_desc = formatString(item[2])
			const serv_sub_cat_qtd = parseFloat(item[3])
			if (typeof item[4] !== 'number') {
				continue
			}
			const serv_sub_cat_valor = parseFloat(item[4])
			const serv_cod = randomNumber()
			const serv_sub_cat_cod = randomNumber()
			const serv_sub_cat_serv_cat = randomNumber()
			const id = randomNumber()
			const object = {
				id,
				serv_sub_cat_subitem_der: serv_sub_cat_subitem_der,
				serv_sub_cat_desc,
				ob_serv_med_desc,
				serv_sub_cat_qtd,
				serv_sub_cat_valor,
				serv_desc: serv_desc,
				prog_serv_por_cen_total: 0,
				serv_sub_cat_disp: 0,
				serv_cat_desc: serv_desc,
				serv_cod,
				serv_sub_cat_cod,
				serv_sub_cat_serv_cat,
				qtd: parseFloat(item[3]),
				serv_fase,
				is_xls_origem: true,
			}
			const validObject = isValid(object)
			if (!validObject) {
				continue
			}
			newItems.push(object)
		} catch (error) {
			console.log(error)
			continue
		}
	}
	return newItems
}
/**
 * Cleans the array by removing leading and trailing whitespace from string elements.
 * @param {Array<any>} array - The array to be cleaned.
 * @param {string} offset - The offset to slice array.
 * @returns {Array<any>} The cleaned array.
 * @example const cleanedArray = cleanArray(myArray);
 */
const cleanArray = (array, offset) => {
	const newArray = array.map(item => {
		if (Array.isArray(item)) {
			const newItem = []
			item.forEach(value => {
				if (value !== null) {
					const trimValue = value
					newItem.push(trimValue)
				}
			})
			return newItem
		}
		return item
	})
	const rows_xls_without_trash = newArray.filter(item => {
		return item.length > 0
	})
	if (offset > 0) {
		return rows_xls_without_trash.slice(offset)
	}
	return rows_xls_without_trash.slice()
}
/**
 * Flatten an array of arrays into a single array.
 * @param {Array<Array>} arrayOfArrays - The array of arrays to be flattened.
 * @returns {Array} - The flattened array.
 */
function flattenArray(arrayOfArrays) {
	return arrayOfArrays.reduce((acc, val) => acc.concat(val), [])
}
/**
 * Verifica se um item já existe em um array através de uma propriedade específica.
 * @param {Array} arr - O array para verificar.
 * @param {string} prop - A propriedade por qual verificar a existência do item.
 * @param {*} value - O valor da propriedade para verificar.
 * @example
 * Exemplo de uso:
 * const pessoas = [
 *	{ id: 1, nome: 'João' },
 *	{ id: 2, nome: 'Maria' },
 *	{ id: 3, nome: 'Pedro' }
 *  ]
 *console.log(itemExistsByProperty(pessoas, 'id', 2)) // Saída: true
 *console.log(itemExistsByProperty(pessoas, 'nome', 'Ana')) // Saída: false
 * @returns {boolean} Retorna true se o item existir no array com a propriedade específica, caso contrário, retorna false.
 */
function itemExistsByProperty(arr, prop, value) {
	if (!arr?.length) {
		return false
	}
	for (let i = 0; i < arr?.length; i++) {
		if (arr[i][prop] === value) {
			return true
		}
	}
	return false
}
/**
 * Generates an array of objects with random hexadecimal colors and their corresponding brightness.
 *
 * @param {number} count - The number of colors to generate.
 * @returns {Array<{ color: string, brightness: number }>} An array of objects containing random hexadecimal colors and their brightness.
 */
function generateRandomColorPaletteWithBrightness(count = 2) {
	/**
	 * Generates a random hexadecimal color.
	 *
	 * @returns {string} A random hexadecimal color.
	 */
	function getRandomColor() {
		return '#' + Math.floor(Math.random() * 16777215).toString(16)
	}

	/**
	 * Calculates the brightness of a color.
	 *
	 * @param {string} color - The hexadecimal color string.
	 * @returns {number} The brightness value (0 to 255).
	 */
	function calculateBrightness(color) {
		// Convert hex color to RGB
		const r = parseInt(color.substring(1, 3), 16)
		const g = parseInt(color.substring(3, 5), 16)
		const b = parseInt(color.substring(5, 7), 16)

		// Calculate brightness (using luminance formula)
		return (0.2126 * r) + (0.7152 * g) + (0.0722 * b)
	}

	const colors = []
	for (let i = 0; i < count; i++) {
		const color = getRandomColor()
		const brightness = calculateBrightness(color)
		colors.push({ color, brightness })
	}
	return colors
}
/**
 * Paginates an array of objects based on the specified page number and page size.
 * 
 * @param {Object[]} array - The array of objects to paginate.
 * @param {number} pageNumber - The current page number (1-based index).
 * @param {number} pageSize - The number of items per page.
 * @returns {Object[]} The paginated array of objects for the specified page.
 */
function paginateArray(array, pageNumber, pageSize) {
	const startIndex = (pageNumber - 1) * pageSize
	const endIndex = startIndex + pageSize
	return array.slice(startIndex, endIndex)
}
/**
 * Paginates an array into chunks of the specified size.
 * @param {Array} array - The array to paginate.
 * @param {number} pageSize - The size of each page.
 * @returns {Array} An array of pages, where each page is an array of items.
 */
function paginateToPostApi(array, pageSize) {
	const pages = []
	for (let i = 0; i < array.length; i += pageSize) {
		pages.push(array.slice(i, i + pageSize))
	}
	return pages
}
/**
 * Divides a specified number of months by a percentage and returns an array of objects.
 *
 * This function calculates a percentage share for each month within a given total number of months, starting from an initial date. It creates an array of objects containing the calculated percentage and the corresponding date for each month.
 *
 * @param {number} differenceInMonths The total number of months to divide.
 * @param {string} dateInit The initial date in 'YYYY-MM-DD' format.
 * @returns {array} An array of objects with properties:
 *   - `acob_perc_prev` (number): The percentage share for each month.
 *   - `acob_date_prev` (string): The date in 'YYYY-MM-DD' format for each month.
 * @throws {Error} If an unexpected error occurs during the process.
 */
function divideMonthByPercentage(differenceInMonths, dateInit, dateFinal) {
	try {
		// Handle invalid input (zero months)
		if (differenceInMonths === 0 || !dateInit) {
			return []
		}

		// Calculate percentage per month
		const acobPercPrev = 100 / differenceInMonths

		// Handle division by zero
		if (acobPercPrev === 0) {
			return []
		}

		// Create initial Date object
		const tempAcobDatePrev = new Date(dateInit)
		const result = []

		for (let i = 0; i <= differenceInMonths; i++) {
			const acobDatePrev = new Date(tempAcobDatePrev); // Clone to avoid modification
			acobDatePrev.setMonth(acobDatePrev.getMonth() + i); // Add one month
			const acob_date_prev_fim = new Date(tempAcobDatePrev)
			acob_date_prev_fim.setMonth(acob_date_prev_fim.getMonth() + i)
			acob_date_prev_fim.setDate(acob_date_prev_fim.getDate() + 30)

			if (dateFinal && acob_date_prev_fim > new Date(dateFinal)) {
				acob_date_prev_fim.setTime(new Date(dateFinal).getTime());  // Replace with dateFinal if it's earlier
			}

			result.push({
				id: randomNumber(),
				acob_perc_prev: parseFloat(acobPercPrev * i).toFixed(2),
				acob_date_prev: acobDatePrev.toISOString().slice(0, 10), // Format date as YYYY-MM-DD,
				acob_date_prev_fim: acob_date_prev_fim.toISOString().slice(0, 10)
			});
		}

		if (dateFinal) {
			const lastResult = result[result.length - 1];
			const lastDateFim = new Date(lastResult.acob_date_prev_fim);

			if (lastDateFim < new Date(dateFinal)) {
				lastResult.acob_date_prev_fim = new Date(dateFinal).toISOString().slice(0, 10);
			}
		}

		// Sort result by date in ascending order
		result.sort((a, b) => new Date(a.acob_date_prev) - new Date(b.acob_date_prev))

		return result
	} catch (error) {
		console.log(error)
		return []
	}
}
const sumArray = (temp_items, key) => {
	if (!temp_items?.length) {
		return 0
	}
	const sumItems = temp_items.reduce((prev, next) => Number(prev) + Number(next[key]), 0)
	return sumItems
}
/**
 * Filters an array of segments and calculates the quantity of records in the array
 * that are smaller than the specified value.
 * @param {number} valueSmallerToCompare - The value used to filter segmentsData array.
 * @param {number} ipc_tip_cod - The code of the index type for which the quantity of values smaller than valueSmallerToCompare is to be calculated.
 * @param {Array} segmentsData - The array of segments data.
 * @param {string} key_of_value - The name of the key containing the value of the index.
 * @returns {Object} An object containing the total number of values smaller than valueSmallerToCompare and an error flag.
 * @property {number} total - The total number of values smaller than valueSmallerToCompare.
 * @property {boolean} isError - Indicates if an error occurred during the execution.
 * @property {Error} [error] - The error object if an error occurred.
 */
function getLengthValuesSmallerThan({
	valueSmallerToCompare,
	ipc_tip_cod,
	segmentsData,
	key_of_value,
}) {
	try {
		if (!Array.isArray(segmentsData) || !ipc_tip_cod || !valueSmallerToCompare) {
			return {
				length: 0,
				isError: true,
			}
		}
		const valuesFromIPCIrr = segmentsData.filter(item => item.ipc_tip_cod === ipc_tip_cod).map(item => item[key_of_value])
		return {
			total: valuesFromIPCIrr.filter(value => value <= valueSmallerToCompare).length,
			isError: false,
		}
	} catch (error) {
		return {
			total: 0,
			isError: true,
			error: error,
		}
	}
}

const handleNewItemPavementLIPC = () => {

}
/**
 * Sums the `targetField` of products with the same `id` in an array of products.
 *
 * @param {Array} array - The array of objects, where each product is an object with `id` and `targetField`.
 * @param {string} field - The name field that used group array to sum.
 * @param {Array|string} targetField -The name field that used sum array`.
 * @returns {Array} - A new array of products with summed `targetField` for products with the same `id`.
 *
 * @example
 * const produtos = [
 *   { id: 1, targetField: 10 },
 *   { id: 2, targetField: 20 },
 *   { id: 1, targetField: 30 },
 *   { id: 3, targetField: 40 },
 *   { id: 2, targetField: 50 }
 * ];
 * 
 * const produtosSomados = sumArrayByField(produtos);
 * console.log(produtosSomados);
 * // Output:
 * // [
 * //   { id: 1, targetField: 40 }, // 10 + 30
 * //   { id: 2, targetField: 70 }, // 20 + 50
 * //   { id: 3, targetField: 40 }
 * // ]
 */
const sumArrayByField = (array, field, targetField) => {
	const result = array.reduce((acc, item) => {
		if (!acc[item[field]]) {
			acc[item[field]] = { ...item }
		} else {
			targetField.forEach(row => {
				if (item[row] !== undefined || item[row] !== null) {
					acc[item[field]][row] += item[row]
				}
			})
		}
		return acc
	}, {})

	return Object.values(result)
}
/**
 * Sorts an array of dates in Brazilian format (dd/mm/yyyy) from the earliest to the latest.
 *
 * @param {Array<string>} dates - The array of dates to be sorted.
 * @param {string|null} dates - The split used to be sorted.
 * @returns {Array<string>} - The sorted array of dates.
 *
 * @example
 * const dates = ['25/01/2026', '15/03/2024', '10/12/2025', '01/01/2024'];
 * const sortedDates = sortBrazilianDates(dates);
 * console.log(sortedDates);
 * // Output: ['01/01/2024', '15/03/2024', '10/12/2025', '25/01/2026']
 */
const sortBrazilianDates = (dates, split = '/') => {
	return dates.sort((a, b) => {
		const [dayA, monthA, yearA] = a.split(split).map(Number);
		const [dayB, monthB, yearB] = b.split(split).map(Number);

		const dateA = new Date(yearA, monthA - 1, dayA);
		const dateB = new Date(yearB, monthB - 1, dayB);

		return dateA - dateB;
	});
};
export {
	sortItems,
	filter,
	filterAndRemove,
	filterBetween,
	filterMoreOne,
	filterValuesUnic,
	filterWord,
	find,
	isExist,
	calcItemsOversight,
	groupBy,
	filterAndRemoveRepeated,
	filterAndRemoveRepeatedByProps,
	verifyLength,
	refactorRowsWithServices,
	cleanArray,
	flattenArray,
	itemExistsByProperty,
	containsKeyword,
	pushAtIndex,
	generateRandomColorPaletteWithBrightness,
	paginateArray,
	getLengthValuesSmallerThan,
	handleNewItemPavementLIPC,
	divideMonthByPercentage,
	sumArray,
	paginateToPostApi,
	sumArrayByField,
	sortBrazilianDates,
}


