import React, { useContext, useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { requestContext } from '../../../context/requestContext'
import { formatDate, formateValidatedDate, generateDateTimeStampJS, parseFromMysqlDateToJSDate } from '../../../services/dateHandless'
import { filterAndRemove, filterAndRemoveRepeated, find, groupBy } from '../../../services/arrayHandless'
import { allowedImageExtensions, allowedMoviesExtensions, buttosExportReport, config, closeModal, namesScreens, openModal } from '../../../constants'
import { formContext } from '../../../context/formContext'
import RenderItem from './renderItem'
import { handleError, handleWaring } from '../../../services/isValid'
import ReportNCPhotosPDF from '../../report/byOversight/view/reportNCPhotos'
import { styles } from '../../../components/form/report/exportReport/styles'
import ReportOversightRoutineView from '../../report/routineMaintance/view'
import FilterStatusOversight from '../../../components/form/filters/statusOversight'
import { generateBase64, getExt } from '../../../services/files'
import { randomNumber } from '../../../services/randomId'
import returnPosition from '../../../services/geolocation'
import { ImageBox } from '../../../components/newOversight/PhotoSection/ImageBox'
import Spinner from '../../../components/spinner'
import { authContext } from '../../../context/authContext'
const OversightView = () => {
	const inputRef = useRef(null)
	const [openModalFisc, setOpenModalFisc] = useState(closeModal)
	const [attachFiles, setAttachFiles] = useState([])
	const [pedVendaCodsToReport, setPedVendaCodsToReport] = useState('')
	const [allowAddReturn, setAllowAddReturn] = useState(null)
	const [allowChangeStatus, setAllowChangeStatus] = useState(null)
	const { user } = useContext(authContext)
	useEffect(() => {
		const loadConfig = async () => {
			if (user?.set_nivel === 0) {
				return
			}
			const ped_venda_status_executado = await config('ped_venda_status_executado')
			if (!ped_venda_status_executado) {
				return
			}
			const { config_para_value } = ped_venda_status_executado
			setAllowAddReturn(Number(config_para_value))
		}
		loadConfig()
	}, [user])
	const location = useLocation()
	const { state: {
		ped_venda_cod,
		ped_venda_status,
		fisc_obs,
		pvs_loc_foto_pedidovenda,
		serv_sub_cat_desc,
		st_desc,
		pvs_loc_foto_legenda,
		sisf_sinc_id
	},
		handleForm,
		setBases64,
		onChange } = useContext(formContext)
	useEffect(() => {
		const getPedVendaCods = () => {
			setPedVendaCodsToReport(String(ped_venda_cod).split(',').sort((a, b) => Number(a) - Number(b)).join(','))
		}
		getPedVendaCods()
	}, [])
	const {
		data: items,
		getItemReportOversight,
		setInfoRequest,
		postItemOversightService,
		status
	} = useContext(requestContext)
	useEffect(() => {
		const configAllowChangeStatus = async () => {
			if (!status?.data?.length) {
				return
			}
			const ped_venda_status_pendente_execucao = await config('ped_venda_status_pendente_execucao')
			if (!ped_venda_status_pendente_execucao) {
				return
			}
			const { config_para_value } = ped_venda_status_pendente_execucao
			const { data } = status
			const { data: findStatus } = find(data, 'st_cod', Number(config_para_value))

			setAllowChangeStatus(false)
			handleForm({
				...findStatus,
				ped_venda_status: findStatus.st_cod
			})
		}
		if (user?.set_nivel === 3) {
			configAllowChangeStatus()
		} else {
			setAllowChangeStatus(true)
		}
	}, [user, status, openModalFisc])
	const [generateReport, setGenerateReport] = useState({
		complet: false,
		simplified: false,
	})
	useEffect(() => {
		const controllScrollView = () => {
			window.scrollTo(0, 0)
		}
		controllScrollView()
	}, [generateReport])
	useEffect(() => {
		const generateLegendToAttachments = () => {
			if (st_desc && serv_sub_cat_desc) {
				handleForm({
					pvs_loc_foto_legenda: `Foto ou Vídeo referente a atividade ${serv_sub_cat_desc} comprovando que atividade está ${st_desc}`
				})
			}
		}
		generateLegendToAttachments()
	}, [serv_sub_cat_desc, st_desc])
	const [csvData, setCsvData] = useState(null)
	const [dataExportPDF, setDataExportPDF] = useState([])
	const [groupedItems, setGroupedItems] = useState(null)
	const navigate = useNavigate()
	const fetchReport = () => {
		if (!pedVendaCodsToReport) {
			return
		}
		return getItemReportOversight({
			id: pedVendaCodsToReport
		})
	}
	useEffect(() => {
		fetchReport()
	}, [pedVendaCodsToReport])
	useEffect(() => {
		if (items?.data[0]?.pedido_venda?.ped_venda_cod) {
			prepareDataToPDF()
			prepareDataToXls()
			agroupedItems()
		}
	}, [items])
	useEffect(() => {
		if (dataExportPDF?.length && location.state?.id_type_report) {
			const { state: { id_type_report } } = location
			if (id_type_report === 1) {
				return setGenerateReport({
					complet: true,
					simplified: false,
				})
			}
			return setGenerateReport({
				complet: false,
				simplified: true
			})
		}
	}, [dataExportPDF, location])
	const agroupedItems = () => {
		try {
			const groups = groupBy(items.data, 'pedido_venda.conc_nome')
			setGroupedItems(groups)
		} catch (error) {
			console.log(error)
			setInfoRequest(handleError('Não foi possível agrupar as Ncs por Concessionária'))
		}
	}
	const prepareDataToXls = () => {
		const dataToExportXls = []
		try {
			for (const item of items?.data) {
				const { pedido_venda_servico, pedido_venda_fiscalizacao } = item
				if (Array.isArray(pedido_venda_servico)) {
					for (const el of pedido_venda_servico) {
						let fotos = ''
						let fisc = ''
						const { pedido_venda_servico_foto } = el
						if (pedido_venda_servico_foto?.length > 0) {
							fotos = el?.pedido_venda_servico_foto
								.map((foto) => foto?.pvs_loc_foto_path)
								?.join(',')
						}
						if (pedido_venda_fiscalizacao?.legend > 0) {
							fisc = pedido_venda_fiscalizacao
								.map((fisc_) => fisc_.fisc_obs)
								.join(',')
						}
						dataToExportXls.push({
							Codigo_da_ocorrencia: item.pedido_venda.ped_venda_cod,
							Concessionaria: item.pedido_venda.conc_nome,
							Descricao_do_lote: item.pedido_venda.lot_conc_desc,
							Data_constatacao: formatDate(
								item.pedido_venda.ped_venda_date
							),
							Status: item.pedido_venda.st_desc,
							Para_rodovia: item.pedido_venda.rod_desc,
							Responsavel: item.pedido_venda.name,
							Servico_solicitado: el.pvs_obs,
							Situacao_servico_solicitado: el.st_desc,
							Prazo_finalizacao: formatDate(
								el?.pvs_prazo_fim?.replace('000Z', '')
							),
							Km_inicial: el.pv_loc_km_ini,
							M_inicial: el.pv_loc_km_init_mts,
							Km_final: el.pv_loc_km_fim,
							M_final: el.pv_loc_km_fim_mts,
							Sentido_da_Rodovia: el.rod_sent_desc,
							Observacoes_sobre_local: el.pv_loc_obs,
							Fiscalizacao: fisc,
							Fotos: fotos,
						})
					}
				}
			}
			if (dataToExportXls.length > 0) {
				const headers = Object.keys(dataToExportXls[dataToExportXls.length - 1])
				const justDealeership = dataToExportXls.map((item) => ({
					Concessionaria: item.Concessionaria,
				}))
				const removeDealershipRepeated =
					filterAndRemoveRepeated(justDealeership)
				const fileName = removeDealershipRepeated
					.map((item) => item.Concessionaria)
					.join('-')
				setCsvData({
					data: dataToExportXls,
					headers: headers,
					fileName: fileName,
				})
			}
		} catch (error) {
			console.log(error)
		}

	}
	const prepareDataToPDF = () => {
		try {
			const dataToExportPDF = []
			for (const item of items?.data) {
				const otherPhotos = []
				if (!item.pedido_venda_servico?.length) {
					continue
				}
				const mainPhotos = item.pedido_venda_servico[0]?.pedido_venda_servico_foto?.slice(0, 4)
				if (!item.pedido_venda_servico[0]?.pedido_venda_servico_foto?.length) {
					continue
				}
				const length = item.pedido_venda_servico[0].pedido_venda_servico_foto.length
				for (let i = 4; i < length; i += 8) {
					otherPhotos.push(item.pedido_venda_servico[0].pedido_venda_servico_foto.slice(i, i + 8))
				}
				dataToExportPDF.push({
					pedido_venda: item.pedido_venda,
					pedido_venda_servico: [{
						...item.pedido_venda_servico[0],
						pedido_venda_servico_foto: mainPhotos
					}],
					otherPhotos
				})
			}
			setDataExportPDF(dataToExportPDF)
		} catch (error) {
			console.log(error)
		}
	}
	const [openDropDown, setOpenDropDown] = useState('')
	const handleOpenDropDown = () => {
		if (openDropDown === '') {
			return setOpenDropDown('show')
		}
		return setOpenDropDown('')
	}
	const handleExportReport = ({ id }) => {
		if (!items?.data?.length) {
			return
		}
		switch (id) {
			case 1:
				setGenerateReport({
					complet: true,
					simplified: false,
				})
				break;
			default:
				setGenerateReport({
					complet: false,
					simplified: true
				})
				break;
		}
	}
	const handleCancel = () => {
		setGenerateReport({
			complet: false,
			simplified: false
		})
	}
	const handleEdit = (item, navigateToScreen = true) => {
		try {
			if (ped_venda_status === allowAddReturn) {
				return setInfoRequest(handleWaring('Você não pode editar essa Nc pois ela já está Executada'))
			}
			const {
				pedido_venda,
				pedido_venda_servico
			} = item
			if (pedido_venda_servico?.length) {
				const firstItem = pedido_venda_servico[0]
				const {
					pv_loc_obs,
					pv_loc_km_fim,
					pv_loc_km_fim_mts,
					pv_loc_km_ini,
					pv_loc_km_init_mts,
					pv_loc_sentido,
					pvs_servico,
					pvs_servico_cat,
					pvs_servico_sub_cat,
					pvs_prazo_fim,
					pedido_venda_servico_foto,
					pvs_cod: pvs_loc_foto_pedidovenda
				} = firstItem
				handleForm({
					...firstItem,
				})
				if (pv_loc_obs) {
					handleForm({
						comp_descricao: pv_loc_obs
					})
				}
				let formated_pvs_prazo_fim = formateValidatedDate(parseFromMysqlDateToJSDate(pvs_prazo_fim))
				if (formated_pvs_prazo_fim === "NaN-aN-aN") {
					formated_pvs_prazo_fim = pvs_prazo_fim.slice(0, 10)
				}
				handleForm({
					serv_cod: pvs_servico,
					serv_cat_cod: pvs_servico_cat,
					serv_sub_cat_cod: pvs_servico_sub_cat,
					rod_sent_cod: pv_loc_sentido,
					pvs_loc_foto_pedidovenda,
				})
				if (pv_loc_km_fim !== null &&
					pv_loc_km_fim_mts !== null &&
					pv_loc_km_ini !== null &&
					pv_loc_km_init_mts !== null
				) {
					handleForm({
						rod_km_fim: pv_loc_km_fim,
						rod_km_fim_mt: pv_loc_km_fim_mts,
						rod_km_ini: pv_loc_km_ini,
						rod_km_ini_mt: pv_loc_km_init_mts,
						serv_cat_prazo: formated_pvs_prazo_fim
					})
				}
				if (pedido_venda_servico_foto?.length) {
					setBases64(pedido_venda_servico_foto.map(item => ({
						...item,
						id: item.pvs_loc_foto_cod,
						base64: item.pvs_loc_foto_path
					})))
				}
			}
			const { ped_venda_concessionaria, ped_venda_rodovia } = pedido_venda
			handleForm({
				...pedido_venda,
				conc_cod: ped_venda_concessionaria,
				rod_cod: ped_venda_rodovia
			})
			if (navigateToScreen) {
				navigate(namesScreens.OVERSIGHTNEW)
			}
		} catch (error) {
			setInfoRequest(handleError(String(error)))
		}
	}
	const handleOpenModalFisc = (item) => {
		handleEdit(item, false)
		setOpenModalFisc(openModal)
	}
	const handleAttach = () => {
		if (inputRef.current?.click) {
			inputRef.current?.click()
		}
	}
	const onChangeFile = async ({ target: { files } }) => {
		try {
			const attachmentsAdd = []
			const { lat: pvs_loc_foto_lat, lng: pvs_loc_foto_lng } = await returnPosition()
			if (!files?.length) {
				throw new Error("Você deve pelo menos selecionar uma mídia para dar o retorno");
			}
			for (const file of files) {
				const { name } = file
				const ext = getExt(name)
				const findValidExtImage = allowedImageExtensions.find(i => i === ext)
				const findValidExtMovie = allowedMoviesExtensions.find(i => i === ext)
				if (findValidExtImage || findValidExtMovie) {
					const base64 = await generateBase64(file)
					const pvs_loc_foto_cod = randomNumber()
					attachmentsAdd.push({
						id: pvs_loc_foto_cod,
						pvs_loc_foto_cod,
						pvs_loc_foto_path: base64,
						pvs_loc_foto_legenda: fisc_obs || pvs_loc_foto_legenda,
						pvs_loc_foto_pedidovenda,
						created_at: generateDateTimeStampJS(),
						pvs_loc_foto_lat,
						pvs_loc_foto_lng
					})
				}
			}
			if (!attachmentsAdd.length) {
				throw new Error("Você deve pelo menos selecionar uma mídia para dar o retorno");
			}
			if (attachFiles.length) {
				return setAttachFiles([
					...attachFiles,
					...attachmentsAdd
				])
			}
			setAttachFiles(attachmentsAdd)
		} catch (error) {
			setInfoRequest(handleError(String(error)))

		}
	}
	const handleCloseModal = () => {
		setOpenModalFisc(closeModal)
		setAttachFiles([])
	}
	const handleRemove = (item) => {
		try {
			const { id } = item
			const { data: filteredItems } = filterAndRemove(attachFiles, 'id', id)
			setAttachFiles(filteredItems)
		} catch (error) {
			setInfoRequest(handleError(String(error)))
		}
	}
	const handleSubmitFisc = async () => {
		if (!attachFiles.length) {
			return setInfoRequest(handleWaring('Você precisa pelo adicionar uma foto para o retorno'))
		}
		if (!pvs_loc_foto_pedidovenda) {
			return setInfoRequest(handleError('Não foi possível completar sua solicitação'))
		}
		const payload = {
			pedido_venda_servico_foto: attachFiles,
			pvs_status: ped_venda_status,
		}
		const response = await postItemOversightService({
			id: pvs_loc_foto_pedidovenda,
			...payload
		})
		setOpenModalFisc(closeModal)
		if (response) {
			fetchReport()
		}
	}
	if (generateReport.complet) {
		return (
			<ReportNCPhotosPDF handleCancel={handleCancel} reportData={dataExportPDF} />
		)
	}
	if (generateReport.simplified) {
		return (
			<ReportOversightRoutineView handleCancel={handleCancel} reportData={dataExportPDF} />
		)
	}
	return (
		<div id='contant'>
			<div className='card'>
				<div className='card-body'>
					<div className='row'>
						<div className='col-3'>
							<div className='dropdown' onClick={handleOpenDropDown}>
								<button className={`btn btn-success dropdown-toggle btn-sm ${openDropDown}`} type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
									Exportar <i className='fa fa-download' aria-hidden='true'></i>
								</button>
								<ul className={`dropdown-menu ${openDropDown}`}
									aria-labelledby='dropdownMenuButton1'
									style={openDropDown === 'show' ? { ...styles } : {}}
								>
									{buttosExportReport.filter(item => item.id !== 0).map(item => (
										<li key={item.id}>
											<Link onClick={() => handleExportReport(item)} className='dropdown-item' to='#'>
												{item.label} <i className={item.icon}></i>
											</Link>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className='col-3'>
							<Link to='#' className='text-dark' onClick={fetchReport}>
								Recarregar <Spinner direction={'center'} label={'   '} />
							</Link>
						</div>
						<div className='col-4'>
							<p>Você está visualizando a(s) seguinte(s) Nc(s) {String(pedVendaCodsToReport)}</p>
						</div>
						<div className='col-2'>
							<button className='btn btn-sm btn-secondary' onClick={() => navigate(namesScreens.OVERSIGHT)}>
								Voltar <i className='fa fa-chevron-left' aria-hidden='true'></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			{groupedItems ? (
				Object.keys(groupedItems).sort((a, b) => a - b).map(conc_nome => (
					<div className='col-12 p-3' key={conc_nome}>
						<h4>NC's da Concessionária {conc_nome}</h4>
						{groupedItems[conc_nome].length ?
							groupedItems[conc_nome][0]?.pedido_venda?.ped_venda_cod &&
							groupedItems[conc_nome].sort((a, b) => a?.pedido_venda?.ped_venda_cod - b?.pedido_venda?.ped_venda_cod).map(item => (
								<RenderItem
									handleEdit={handleEdit}
									generateReport={generateReport}
									item={item}
									key={item?.pedido_venda?.ped_venda_cod}
									handleOpenModalFisc={handleOpenModalFisc}
									allowAddReturn={allowAddReturn}
								/>
							))
							: null}
					</div>
				))
			) : null}
			<div className={openModalFisc.className} id={`modal_fiscalizacao_${ped_venda_cod}`} style={{
				...openModalFisc.style,
				overflowY: 'scroll'
			}}
				tabIndex='-1' role='dialog' aria-labelledby={`modal_fiscalizacao_label_${ped_venda_cod}`} aria-hidden='true'>
				<div className='modal-dialog modal-lg'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title' id={`modal_fiscalizacao_label_${ped_venda_cod}`}>
								Dar retorno na Nc° {sisf_sinc_id || ped_venda_cod}
							</h5>
							<button type='button' onClick={handleCloseModal} className='close' data-dismiss='modal' aria-label='Close'>
								<span aria-hidden='true'>&times;</span>
							</button>
						</div>
						<div className='modal-body'>
							<div className='row'>
								<div className='mb-3 col-md-4 col-sm-6'>
									{!allowChangeStatus ? (
										<div className="form-group">
											<label>Situação</label>
											<input
												className='form-control'
												value={st_desc}
												disabled
											/>
										</div>
									) : (
										<FilterStatusOversight multiple={false} />
									)}
								</div>
								<div className='form-group col-md-8 col-sm-6'>
									<label>Observação</label>
									<textarea
										className='form-control'
										name='fisc_obs'
										value={fisc_obs}
										onChange={onChange}
										rows={3}
										placeholder='Descreva aqui alguma observação sobre o retorno (Opcional)'
									>
										{fisc_obs}
									</textarea>
								</div>
								{!attachFiles.length ? (
									<div className='form-group col-12'>
										<label>Anexe uma(as) foto(s) ou vídeo(s), as mídias adicionadas apareceram logo abaixo</label>
										<button className='btn btn-info btn-lg btn-block' onClick={handleAttach}>
											<i className='fa fa-plus'></i>
										</button>
									</div>
								) : null}
								<div className='col-12 mb-3'>
									<label className='mb-3'>Fotos adicionadas</label>
									{attachFiles.length ? (
										<ImageBox
											handleAttach={handleAttach}
											items={attachFiles}
											handleRemove={handleRemove}
										/>
									) : null}
								</div>
								<div className='col-12'>
									<button className='btn btn-info btn-lg btn-block' onClick={handleSubmitFisc}>
										Enviar retorno <Spinner direction={'center'} label={'   '} />
									</button>
								</div>
								<input className='d-none'
									onChange={onChangeFile}
									ref={inputRef}
									type='file'
									multiple
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default OversightView
